import styled from 'styled-components'

export const LoginButtomEl = styled.button`
    margin-bottom: 1em;
    cursor: pointer;
    background-color: #00A651;
    margin-top: 2em;
    font-size: 18px;
    height: 3em;
    width: 18em;
    text-align: center;
    border-radius: 11px;
    color: white;
`

export const MainDivEl = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const DivEl = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
`

