import { IonContent, IonHeader, IonIcon, IonItem, IonModal } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { DivHMaxWidth } from "../../../components/div/styled";
import Donut from "../../../components/donut";
import MoneySymbol from "../../../components/money-symbol";
import { MovProductsResponse } from "../../../dto/mov-produtos/mov-produtos-response";
import { doubleToStr } from "../../../utils/format-util";
import { MovProductsFilter } from "../service";
import { ModalButtonEl } from "../styled";
import ModalProducts from "./modal/products";
import ModalSalesDetail from "./modal/sales-detail";

export interface Paran {
   item : MovProductsResponse
   filter: MovProductsFilter
}

export declare type ModalContentType = 'options' | 'sale-details' | 'products'

const Line: React.FC<Paran> = ({item, filter}) => {
   const [modalStatus, setModalStatus] = useState('options' as ModalContentType)
   const [open, setOpen] = useState(false);

   const modal = useRef<HTMLIonModalElement>(null)

   const closeModal = () => {
      modal.current?.dismiss();
  }

  useEffect(() => {
   if (!open) {
      setModalStatus('options')
   }
  }, [open])


  const buildContentOptions = () => {
      return  <>
         <IonHeader >
            {/* Botão de fechar o modal. */}
            <div style={{textAlign: 'end', marginTop: '1em', marginRight: '1em', float: 'right'}}>
               <IonIcon icon='close-outline' style={{color: 'red', fontSize: '1.8em'}} onClick={() => closeModal()} />
            </div>
            

            <div style={{textAlign: 'center', marginTop: '1em', marginBottom: '0.5em'}}>
               <div>
                  <span style={{ fontSize: '0.9em', fontWeight: '550'}}>
                     {item.label}
                  </span>
               </div>
               <div> 
                  <span>
                     Selecione uma opção
                  </span>
               </div>
            </div>   
         </IonHeader>

      <IonContent>
         <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: '0 1.3em 1em 0', height: '100%'}}>
            <ModalButtonEl onClick={() => {setModalStatus('sale-details')}}>
               Detalhes da Venda
            </ModalButtonEl>    
            <ModalButtonEl onClick={() => {setModalStatus("products")}}>
               Produtos
            </ModalButtonEl>

            <ModalButtonEl onClick={() => closeModal()}>
               Cancelar
            </ModalButtonEl>
         </div>
      </IonContent>
   </>
  }

  const buildModal = () => {

   return <IonModal isOpen={open} 
      className={modalStatus === 'options' ? 'modal-small' : 'modal-large'} 
      ref={modal} onDidDismiss={() => { setOpen(false)}}
   >

      {modalStatus === 'options' &&
        buildContentOptions()
      } 

      {modalStatus === 'sale-details' &&
         <ModalSalesDetail closeModal={closeModal} item={item} />
      }

      {modalStatus === 'products' &&
         <ModalProducts closeModal={closeModal} filter={filter} item={item} />
      }

   </IonModal>
  }

   return (
      <IonItem style={{fontSize: '1em'}}  
         onClick={() => {
            setOpen(true)
         }}
      >
         <DivHMaxWidth style={{ width: '100%', alignItems: 'center', paddingBottom: 0}}>
            <Donut valuePercent={item.totalSalePercentMt} style={{padding: '0.5em 0 0.5em 0'}} />
            <div>
               <p style={{ fontSize: '0.9em', fontWeight: '600'}}>
                  {`${item.id} - ${item.label}`}
               </p>
               <p style={{fontSize: '0.9em'}}>
                  <span style={{fontSize: '0.6em'}}>{'Venda:   '}</span>
                  <MoneySymbol value={item.totalSaleValueMt} />
               </p>
               <p style={{fontSize: '0.9em'}}>
                  <span style={{fontSize: '0.6em'}}>{'Margem média:   '}</span>
                  {doubleToStr(item.totalMarginMt)}
               </p>
            </div>
         </DivHMaxWidth>

         {buildModal()}

      </IonItem>
   );
};

export default Line;