import { IonButton } from "@ionic/react";
import styled from "styled-components";

export const InputDateStyled = styled.input`
   width: 100%;
   height: 2.5em;
   padding: 0 0.5em;
   fontSize: 1em;
   color: #495957;
   background-clip: padding-box;
   border: 1px solid #ced4da;
   border-radius: 0.2em;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
`
export const ButtonOpenModal = styled(IonButton)`
    /* font-size: 25px;
    display: flex;
    align-items: center;
    background-color: transparent;
    margin-right: 12px; */
`