import { IonButton, IonButtons, IonContent, IonDatetime, IonDatetimeButton, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import * as Yup from 'yup';
import { ButtonOpenModal } from "../../home/filter/styled";
import { PeriodFilter } from "../service";

export interface Paran {
   setData: any;
   filter: PeriodFilter;
}

const Filter: React.FC<Paran> = ({setData, filter}) => {
   const [open, setOpen] = useState(false);
   const formRef = useRef({} as any)
   
   const dateTimePresentation = () => {
      if (filter.aggregationType === 'MONTH') {
         return 'month-year'
      } else if(filter.aggregationType === 'YEAR') {
         return 'year'
      } else {
         return 'date'
      }
   }

   const showDateEnd = () => {
      return filter.aggregationType !== 'HOUR'
   }

   const closeModal = () => {
      setOpen(false);
   }

   const submit = async (values: PeriodFilter) => {
      closeModal()
      // Filter
      values.aggregationType = filter.aggregationType
      setData({
         filter : values
      })
   }

   const applyFilter = () => {
      if (formRef.current) {
         formRef.current.handleSubmit()
      }
   }

   return (
      <>
         <ButtonOpenModal onClick={() => setOpen(true)}>
            <IonIcon className='text-white' icon='options-outline' />
         </ButtonOpenModal>
         
         <IonModal
            isOpen={open}
            onDidDismiss={closeModal}
            breakpoints={[0, 0.2, 0.5, 0.8, 1]}
            initialBreakpoint={0.5}
            backdropBreakpoint={0.2}
         >
            <IonHeader>
               <IonToolbar>
                  <IonButtons slot="start">
                     <IonButton className='text-white' onClick={() => setOpen(false)}>Cancelar</IonButton>
                  </IonButtons>

                  <IonTitle style={{ textAlign: 'center' }}>Filtro</IonTitle>

                  <IonButtons slot="end">
                     <IonButton className='text-white' strong={true} onClick={() => applyFilter()}>Aplicar</IonButton>
                  </IonButtons>
               </IonToolbar>
            </IonHeader>

            <IonContent>

               {/* Formulário de filtro */}
               <Formik
                  innerRef={formRef}
                  initialValues={filter}
                  onSubmit={submit}
                  validationSchema={
                     Yup.object().shape({
                        dateStart: Yup.date().required(),
                        dateEnd: Yup.date().required(),
                     })
                  }
               >
                  {(propsForm) => (
                     <Form id='my-form'>
                        <IonList>
                           <IonItem>
                              <IonLabel>Início :</IonLabel>
                              <IonDatetimeButton datetime="datetimeStart" />

                              <IonModal keepContentsMounted={true} >
                                 <IonDatetime 
                                    id="datetimeStart"
                                    name="dateStart"
                                    onIonChange={propsForm.handleChange}
                                    onIonBlur={propsForm.handleBlur}
                                    value={propsForm.values.dateStart}
                                    locale="pt-BR" 
                                    presentation={dateTimePresentation()}
                                    showDefaultButtons={true} 
                                    style={{width: '100%'}}
                                 />
                              </IonModal>
                           </IonItem>

                           { showDateEnd() &&
                                <IonItem>
                                <IonLabel>Fim :  </IonLabel>
                                <IonDatetimeButton datetime="datetimeEnd"/>
                          
                                <IonModal keepContentsMounted={true}>
                                   <IonDatetime 
                                      id="datetimeEnd"
                                      name="dateEnd"
                                      onIonChange={propsForm.handleChange}
                                      onIonBlur={propsForm.handleBlur}
                                      value={propsForm.values.dateEnd}
                                      locale="pt-BR" 
                                      presentation={dateTimePresentation()}
                                      showDefaultButtons={true}
                                      style={{width: '100%'}}
                                   />
                                </IonModal>
                             </IonItem>
                           }
                        </IonList>
                     </Form>
                  )}
               </Formik>

            </IonContent>
         </IonModal>
      </>
   );
};

export default Filter;