import { IonIcon } from "@ionic/react"
import { MouseEventHandler } from "react"
import { CSSProperties } from "styled-components";
import { ButtonEl } from "./styled"

interface Params {
   title?: string;
   icon?: string // 'time' | 'calendar' | 'business' | 'grid' | 'star' | 'bag' | 'people' | 'cash' | 'cancel' | 'tag' | 'cart';
   onClick?: MouseEventHandler;
   className?: string;
   style?: CSSProperties; 
}

const IconButton: React.FC<Params> = ({ icon, title, onClick, className, style}) => {
   let iconSet:string | undefined = ''

   if (icon === 'time') {
      iconSet = 'time-outline'
   } else if (icon === 'calendar') {
      iconSet = 'calendar-outline'
   } else if (icon === 'business') {
      iconSet = 'business-outline'
   } else if (icon === 'grid') {
      iconSet = 'grid-outline'
   } else if (icon === 'star') {
      iconSet = 'star-outline'
   } else if (icon === 'bag') {
      iconSet = 'bag-outline'
   } else if (icon === 'people') {
      iconSet = 'people-outline'
   } else if (icon === 'cash') {
      iconSet = 'cash-outline'
   } else if (icon === 'cancel') {
      iconSet = 'ban-outline'
   } else if (icon === 'tag') {
      iconSet = 'pricetag-outline'
   } else if (icon === 'cart') {
      iconSet = 'cart-outline'
   } else {
      iconSet = icon
   }


   return (
      <ButtonEl onClick={onClick} className={className} style={style}>
         <IonIcon icon={iconSet} style={{fontSize: '1.5em'}}/>
         <p style={{fontSize: '0.8em', }}>{title}</p>
      </ButtonEl>
   )
}

export default IconButton