import { IonButton, IonButtons, IonContent, IonDatetime, IonDatetimeButton, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { HomeFilterData } from '../../../dto/home/home-filter-data';
import { compareStoreWith, Store } from '../../../dto/store/store';
import HomeContext from '../context';
import { findAllStores } from './service';
import { ButtonOpenModal } from './styled';
import { compareSegmentWith, listSegments } from '../../../dto/segment';

const Filter: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [allStores, setAllStores] = useState([] as Store[])
  const formRef = useRef({} as any)
  const { dataHome, setDataHome } = useContext(HomeContext);

  useEffect(() => {
    if (open) {
      const find = async () => {
        const allStores = await findAllStores()
        setAllStores(allStores)
      }
      find()
    }
  }, [open])

  const closeModal = () => {
    setOpen(false);
  }

  const submit = async (values: HomeFilterData) => {
    closeModal()
    setDataHome({
      filter: values
    })
  }

  const applyFilter = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  return (
    <>
      <ButtonOpenModal onClick={() => setOpen(true)}>
        <IonIcon className='text-white' icon='options-outline' />
      </ButtonOpenModal>

      <IonModal
        isOpen={open}
        onDidDismiss={closeModal}
        breakpoints={[0, 0.2, 0.5, 0.8, 1]}
        initialBreakpoint={0.5}
        backdropBreakpoint={0.2}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton className='text-white' onClick={() => setOpen(false)}>Cancelar</IonButton>
            </IonButtons>

            <IonTitle style={{ textAlign: 'center' }}>Filtro</IonTitle>

            <IonButtons slot="end">
              <IonButton className='text-white' strong={true} onClick={() => applyFilter()}>Aplicar</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>

          {/* Formulário de filtro */}
          <Formik
            innerRef={formRef}
            initialValues={dataHome.filter}
            onSubmit={submit}
            validationSchema={
              Yup.object().shape({
                date: Yup.date().required(),
                stores: Yup.array(),
                segments: Yup.array(),
              })
            }
          >
            {(propsForm) => (
              <Form id='my-form'>
                <IonList>
                  <IonItem>
                    <IonLabel>Início :</IonLabel>
                    <IonDatetimeButton datetime="datetimeStart" />
                    <IonModal keepContentsMounted={true} >
                      <IonDatetime
                        id="datetimeStart"
                        name="date"
                        onIonChange={propsForm.handleChange}
                        onIonBlur={propsForm.handleBlur}
                        value={propsForm.values.date}
                        locale="pt-BR"
                        presentation={'date'}
                        showDefaultButtons={true}
                        style={{ width: '100%' }}
                      />
                    </IonModal>
                  </IonItem>

                  <IonItem>
                    <IonLabel>Segmentos</IonLabel>
                    <IonSelect
                      value={propsForm.values.segments}
                      placeholder="Segmentos "
                      name='segments'
                      compareWith={compareSegmentWith}
                      onIonChange={propsForm.handleChange}
                      onIonBlur={propsForm.handleBlur}
                      multiple={true}
                    >
                      {listSegments(dataHome.filter.organizationIdAtr!).map((store) => (
                        <IonSelectOption key={store.id} value={store}>
                          {store.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>

                  <IonToolbar className='filter-white'>
                    <IonButtons  className='filter-white' slot="end" style={{ marginRight: '2em' }}>
                      <IonButton onClick={() => { propsForm.setFieldValue('segments', []) }} >
                        <IonIcon icon='square-outline' />
                      </IonButton>
                      <IonButton onClick={() => { propsForm.setFieldValue('segments', listSegments(dataHome.filter.organizationIdAtr!)) }} >
                        <IonIcon icon='checkmark-done-outline' />
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>

                  <IonItem>
                    <IonLabel>Unidades</IonLabel>
                    <IonSelect
                      value={propsForm.values.stores}
                      placeholder="Todas as lojas"
                      name='stores'
                      compareWith={compareStoreWith}
                      onIonChange={propsForm.handleChange}
                      onIonBlur={propsForm.handleBlur}
                      multiple={true}
                    >
                      {allStores.map((store) => (
                        <IonSelectOption key={store.id} value={store}>
                          {store.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>

                  <IonToolbar className='filter-white'>
                    <IonButtons slot="end" style={{ marginRight: '2em' }}>
                      <IonButton onClick={() => { propsForm.setFieldValue('stores', []) }} >
                        <IonIcon icon='square-outline' />
                      </IonButton>
                      <IonButton onClick={() => { propsForm.setFieldValue('stores', allStores) }} >
                        <IonIcon icon='checkmark-done-outline' />
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonList>
              </Form>
            )}
          </Formik>
        </IonContent>
      </IonModal>
    </>
  );
};

export default Filter;