import { PeriodValueResponseDto } from "../../dto/home/period-value-response"
import { HomeFilterData } from "../../dto/home/home-filter-data"
import httpClient from "../../service/api"

export interface HomeData {
    filter: HomeFilterData
    periodValuesMonth?: PeriodValueResponseDto
    periodValuesDay?: PeriodValueResponseDto
    date?:Date
}

export const findData = async (filter: HomeFilterData) => {
  const dataMonth = await httpClient.post('/api/home/process-period-month', filter)
  const dataDay = await httpClient.post('/api/home/process-period-day', filter)

  return {
    filter : filter,
    periodValuesMonth: dataMonth.data,
    periodValuesDay: dataDay.data,
    date: new Date()
  } as HomeData
}
