import { DivH } from "../divh/styled"
import MoneySymbol from "../money-symbol"

interface Param {
   title: string
   value: number
}

const SaleDetailsModal: React.FC<Param> = ({title, value}) => {
   return (
      <DivH style={{fontSize: '1em', paddingBottom: '0.5em'}}>
         <p style={{color: 'var(--color-primary)', textAlign: 'start'}}>{title}</p>
         <p style={{color: '#888', textAlign: 'end'}}><MoneySymbol value={value} /></p>
      </DivH>
   )
}

export default SaleDetailsModal 