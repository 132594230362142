import { doubleToStr } from "../../utils/format-util";

interface Param {
    value?: number;
    fontSize?: string;
    isShow?:boolean;
}

const MoneySymbol: React.ElementType<Param> = ({value = 0, fontSize = "0.7em", isShow=true}) => {
    return isShow ? (
            <> 
                <span style={{fontSize: fontSize, fontWeight: "400", marginRight: "0.2em", display: "inline-block"}}>
                    {"R$"}
                </span>
                <span style={{display: "inline-block"}}>
                    {doubleToStr(value)}
                </span>
            </>
        ) : <></>
    ;
}

export default MoneySymbol;