import { ChartHourlySalesResponseDto } from "../../../../dto/home/chart-hourly-sales-response"
import { HomeFilterData } from "../../../../dto/home/home-filter-data"
import httpClient from "../../../../service/api"

export class DataChartHourlySales {
    
    categoriesHours? : number[]
    serieDirectSales? : number[]
    seriePdvSales? : number[]
    seriePreviousMonthSales? : number[]
    seriePreviousYearSales? : number[]

    maxSale? : number
    maxHour? : string

    minSale? : number
    minHour? : string
    minIndex? : number
}

export const findData = async (filter: HomeFilterData) : Promise<DataChartHourlySales> => {
    const response = await httpClient.post('/api/home/chart-hourly-sales', filter)
    const dataChart = response.data as ChartHourlySalesResponseDto

    const result = new DataChartHourlySales();

    // Cria a lista de categorias com todas as horas.
    result.categoriesHours = dataChart.hoursList

    result.serieDirectSales = dataChart.directSalesList
    result.seriePdvSales = dataChart.pdvSalesList

    result.seriePreviousMonthSales = dataChart.previousMonthSalesList
    result.seriePreviousYearSales = dataChart.previousYearSalesList

    // Busca a venda com maior valor.
    result.maxSale = Math.max.apply(null, result.serieDirectSales)
    const maxHour = dataChart.hoursList[dataChart.directSalesList.indexOf(dataChart.directSalesList.find(item => item === result.maxSale)!)]
    result.maxHour = maxHour ? maxHour.toString() : '';

    // Busca a venda com menos valor.
    result.minSale = Math.min.apply(null, result.serieDirectSales)
    const minHour = dataChart.hoursList[dataChart.directSalesList.indexOf(dataChart.directSalesList.find(item => item === result.minSale)!)]
    result.minHour = minHour ? minHour.toString() : ''
    result.minIndex = result.serieDirectSales.indexOf(result.minSale)

    return result
}