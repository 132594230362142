import { IonButton, IonButtons, IonCheckbox, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel, IonMenuButton, IonNote, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonToast } from "@ionic/react"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router"
import * as Yup from "yup"
import { Organization, compareOrganizationWith } from "../../../dto/organization"
import { User } from "../../../dto/user"
import httpClient from "../../../service/api"
import { save } from "./service"

const UsersForm: React.FC = () => {

  const [toast] = useIonToast()
  const history = useHistory()
  const location = useLocation()
  let userState = location?.state as User
  const [allOrganizations, setAllOrganizations] = useState([] as Organization[] | undefined)

  useEffect(() => {
    const init = async () => {
      const response = await httpClient.get('/api/organization')
      setAllOrganizations(response.data)
    }
    init()
  }, [])

  const onSubmit = async (user: User) => {
    await save(user)
    history.replace('/user/list', { refresh: true })

    toast({
      message: "Usuário '" + user.name + "' editado com sucesso.",
      duration: 1_500,
      position: 'top'
    })
  }

  const requiredError = 'Campo obrigatório'
  const lengthError = 'Mínimo de 4 caracteres'

  let valid: any = {
    name: Yup.string().required(requiredError).min(4, lengthError),
    email: Yup.string().email('Email inválido').required(requiredError),
    isAdmin: Yup.boolean(),
    organizations: Yup.array().required()
  }

  if (!userState || !userState.id) {
    valid = {
      ...valid,
      password: Yup.string().required(requiredError).min(4, lengthError),
      passwordConfirm: Yup.string().required(requiredError).min(4, lengthError).oneOf([Yup.ref('password')], 'As senhas não são iguais.')
    }
  }

  const formik = useFormik({
    initialValues: userState,
    onSubmit: onSubmit,
    validationSchema: Yup.object().shape(valid),
    validateOnMount: true,
    enableReinitialize: false
  })

  if (!userState || (userState as any).refresh) {
    return <></>
  }


  const buildInput = (label: string, name: string, type: any = "text") => {
    const val: any = formik.values
    const erros: any = formik.errors
    const msm = erros[name]

    return <IonItem className={`${!msm && 'ion-valid'} ${msm && 'ion-invalid ion-touched'}`}>
      <IonLabel position="stacked">{label}</IonLabel>

      <IonInput
        name={name}
        value={val[name]}
        type={type}
        onIonChange={formik.handleChange}
        onIonBlur={formik.handleBlur}
      />

      {msm &&
        <IonNote slot="error" style={{ color: 'var(--ion-color-danger)' }}>{msm}</IonNote>
      }
    </IonItem>
  }

  const buildContent = () => {
    return (
      <form onSubmit={formik.handleSubmit}>
        {buildInput("Nome", "name")}
        {buildInput("E-mail", "email", "email")}
        {!userState.id &&
          <>
            {buildInput("Senha", "password", "password")}
            {buildInput("Confirmar senha", "passwordConfirm", "password")}
          </>
        }

        <IonItem>
          <IonCheckbox
            checked={formik.values.isAdmin}
            onIonChange={(e) => { formik.setFieldValue("isAdmin", e.detail.checked) }}
            slot="start"
          />
          <IonLabel style={{ marginLeft: "1em" }}>Adiministrador</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel >Organizações : </IonLabel>

          <IonSelect onIonChange={(e: any) => { formik.setFieldValue("organizations", e.detail.value) }}
            value={formik.values.organizations} multiple={true} compareWith={compareOrganizationWith}>

            {allOrganizations!.map((store) => (
              <IonSelectOption key={store.id} value={store}>
                {store.name}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </form>
    )
  }

  return (
    <IonPage>
      <IonHeader style={{ marginBottom: "1em" }}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Usuários</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {buildContent()}
      </IonContent>

      <IonFooter>
        <IonToolbar>
        
          <IonButtons slot="start">
            <IonButton onClick={() => { history.replace('/user/list', { refresh: true }) }} >
              Cancelar
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">

            <IonButton disabled={!formik.isSubmitting && (Array.isArray(formik.errors) || Object.values(formik.errors).toString() !== "")}
              onClick={() => {
                formik.handleSubmit()
              }}>
              Salvar
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default UsersForm
