import { IonText } from '@ionic/react';
import Card from '../../../../components/card';
import { DivH } from '../../../../components/divh/styled';
import Donut from '../../../../components/donut';
import MoneySymbol from '../../../../components/money-symbol';
import { PeriodValueDto } from '../../../../dto/home/period-value-response';

export interface Paran {
  title: string
  value: PeriodValueDto;
}

const CardVenda: React.FC<Paran> = ({title, value}) => {
  return (
    <Card>
      <div style={{marginBottom: '1em', textAlign: 'center'}}>
        <h3>{value.label}</h3> 
        <h2>{title}</h2> 
        <h2><MoneySymbol value={value.totalSaleValueMt}/></h2>
      </div>

      <DivH style={{paddingRight: '0.3em'}}>
        <Donut valuePercent={value.pdvSalePercentMt} />
        <IonText>
          <h4>Autosserviço</h4> 
          <h3><MoneySymbol value={value.pdvSaleValueMt} /></h3> 
        </IonText>
      </DivH>

      <DivH style={{paddingRight: '0.3em'}}> 
        <Donut valuePercent={value.flexSalePercentMt} />
        <IonText>
          <h4>DC</h4> 
          <h3><MoneySymbol value={value.flexSaleValueMt} /></h3> 
        </IonText>
      </DivH>

    </Card>
  )
};

export default CardVenda;
