import { ApexOptions } from 'apexcharts';
import React, { useContext, useEffect, useRef, useState } from 'react';
import 'react-apexcharts';
import CardFullScreen from '../../../../components/card-full-screen';
import Chart from '../../../../components/chart';
import IconTitle from '../../../../components/icon-title';
import { convertHourLabel, convertSaleLabel } from '../../../../utils/format-util';
import HomeContext from '../../context';
import { DataChartHourlySales, findData } from './service';
import { Organization } from '../../../../dto/organization';
import { getCurrentOrganization } from '../../../../service/session';

const ChartHourlySales: React.FC = () => {
  const divContainer = useRef(null as any)
  const { dataHome } = useContext(HomeContext);
  const [dataChart, setDataChart] = useState({} as DataChartHourlySales)

  useEffect(() => {
    const find = async () => {
      const org: Organization = await getCurrentOrganization()

      const dataChart = await findData( {...dataHome.filter, organizationIdAtr: org.id})
      setDataChart(dataChart)
    }
  
    find()
  }, [dataHome])


  const buildChart = () => {

    const options: ApexOptions = {
      chart: {
        toolbar: {
          show: true, 
          tools: {
              zoom: false,
              selection: false,
              download: false,
              pan: false,
              reset: false,
          }
        },
      },
      tooltip: {
        enabled: false
      }
      ,
      fill: {
        type: 'solid',
        opacity: [0.3, 1, 1, 1],
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [5, 3, 3, 3],
        curve: 'straight',
        dashArray: [0, 8, 8, 8]
      },
      xaxis: {
        categories: dataChart.categoriesHours,
        labels: {
          formatter : convertHourLabel,
          rotate: -90,
        }
      },
      yaxis: {
        min: 0,
        labels: {
          formatter : convertSaleLabel,
        },
  
      },
      annotations : {
        points: 
        [
          {
            x: convertHourLabel(dataChart.maxHour),
            y: dataChart.maxSale,
            marker: {
              size: 2,
              strokeColor: 'var(--color-max)',
              fillColor: 'var(--color-max)'
            },
            label: {
              offsetY: -8,
              text: convertHourLabel(dataChart.maxHour) + ': R$' + convertSaleLabel(dataChart.maxSale),
              borderWidth: 0,
              style: {
                color: 'var(--color-max)',
                fontSize: '0.8em',
                background: 'transparent',
              }
            }
          },
  
          {
            x: convertHourLabel(dataChart.minHour),
            y: dataChart.minSale,
            marker: {
              size: 2,
              strokeColor: 'var(--color-min)',
              fillColor: 'var(--color-min)'
            },
            label: {
              offsetY: -8,
              textAnchor: dataChart.minIndex! > dataChart.categoriesHours!.length / 2 ? 'end' : 'start',
              text: convertHourLabel(dataChart.minHour) + ': R$' + convertSaleLabel(dataChart.minSale),
              borderWidth: 0,
              style: {
                color: 'var(--color-min)',
                fontSize: '0.8em',
                background: 'transparent',
              }
            }
          }
        ]
      }
    }
  
    const series: ApexAxisChartSeries = [
      {
        name: "Autosserviço",
        data: dataChart.serieDirectSales!,
        type: 'area',
      },
      {
        name: "DC",
        data: dataChart.seriePdvSales!,
        type: 'line',
      },
      {
        name: "Mês Anterior",
        data: dataChart.seriePreviousMonthSales!,
        type: 'line',
      },
      {
        name: "Ano anterior",
        data: dataChart.seriePreviousYearSales!,
        type: 'line',
      },
    ]

    return <CardFullScreen >
      <IconTitle title='Vendas por faixa hora' icon='clock' />
      <Chart options={options} series={series} type='area' height={200}/>
    </CardFullScreen>

  }

  return (
    <div ref={divContainer}>
      {dataChart.categoriesHours &&
        buildChart()
      }
    </div>
  )
};

export default ChartHourlySales;