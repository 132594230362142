import { DonutValue } from "react-donut-component";
import styled from "styled-components";

interface Props {
    valuePercent: number;
}

export const DonutValueEl = styled(DonutValue)<Props>`
    margin-right: 0.05em;
    font-weight: 555;
    font-size: ${p => p.valuePercent === 100 ? '0.7em' : '0.7em'};
    margin-left: ${p => p.valuePercent === 100 ? '-0.85em' : '-0.7em'};
`;
