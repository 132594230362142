import { IonText } from "@ionic/react";
import Card from "../../../../components/card";
import { DivH } from "../../../../components/divh/styled";
import Donut from "../../../../components/donut";
import MoneySymbol from "../../../../components/money-symbol";
import { PeriodValueDto } from "../../../../dto/home/period-value-response";

export interface Param {
  title: string;
  value: PeriodValueDto;
}

const CardLoss: React.FC<Param> = ({title, value}) => {

  // const getColor = (value:number) => {
  //   return value > 0 ? "var(--color-very-good)" : "var(--color-very-bad)";
  // }

  return (
    <Card>
      <DivH>
        <Donut valuePercent={value.lossValuePercentMt} />
        <IonText>
          <h4>{title}</h4> 
          <h3><MoneySymbol value={value.lossValueMt} /></h3>
        </IonText>
      </DivH>

      {/* <h4 style={{fontSize: "0.8em", textAlign: "left"}}>Teto</h4>
      <DivH>
        <IonText>
          <h3><MoneySymbol value={value.lossGoalValueMt} /></h3>
        </IonText>

        <p style={{fontSize: "0.8em", color: getColor(value.lossGoalValuePercentMt)}}><PercentSymbol value={value.lossGoalValuePercentMt} /></p>
      </DivH> */}
    </Card>
  )
};

export default CardLoss;
