import { IonProgressBar } from '@ionic/react';
import React from 'react';
import 'react-apexcharts';

const Loading: React.FC = () => {
  return (
    <IonProgressBar type="indeterminate" />
  )
};

export default Loading;