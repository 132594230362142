import { IonCardContent } from '@ionic/react';
import React from 'react';
import 'react-apexcharts';
import { FullScreen, useFullScreenHandle } from'react-full-screen';
import { CardEl } from '../card/styled';
import FullScreenButton from '../full-screen-button';

interface Param {
  ref?: any
}

const CardFullScreen: React.FC<Param> = ({children, ref}) => {
  const handle = useFullScreenHandle()

  return (
    <FullScreen handle={handle}>
      <CardEl isActive={handle.active} ref={ref}>
        <IonCardContent style={{height: '100%', paddingBottom: handle.active ? '3em' : ''}}>
          <FullScreenButton handle={handle} />
          {children}
        </IonCardContent>
      </CardEl>
    </FullScreen>
  )
};

export default CardFullScreen;