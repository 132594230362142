import { IonButton, IonButtons, IonContent, IonDatetime, IonDatetimeButton, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { compareStoreWith, Store } from "../../../dto/store/store";
import { findAllBuyers, findAllStores } from "../../home/filter/service";
import { ButtonOpenModal } from "../../home/filter/styled";
import { GoalFilter } from "../service";

export interface Paran {
   setData: any;
   filter: GoalFilter;
}

const Filter: React.FC<Paran> = ({ setData, filter }) => {
   const [open, setOpen] = useState(false);
   const [allStores, setAllStores] = useState([] as Store[])
   const [allBuyers, setAllBuyers] = useState([] as Store[])
   const formRef = useRef({} as any)
   useEffect(() => {
      if (open) {
         const find = async () => {
            const allStores = await findAllStores()
            setAllStores(allStores)
            const allBuyers = await findAllBuyers()
            setAllBuyers(allBuyers)
         }

         find()
      }
   }, [open])

   const closeModal = () => {
      setOpen(false);
   }

   const submit = async (values: GoalFilter) => {
      closeModal()
      // Filter
      setData({
         filter: values
      })
   }

   const applyFilter = () => {
      if (formRef.current) {
         formRef.current.handleSubmit()
      }
   }

   return (
      <>
         <ButtonOpenModal onClick={() => setOpen(true)}>
            <IonIcon className='text-white' icon='options-outline' />
         </ButtonOpenModal>

         <IonModal
            isOpen={open}
            onDidDismiss={closeModal}
            breakpoints={[0, 0.2, 0.5, 0.8, 1]}
            initialBreakpoint={0.5}
            backdropBreakpoint={0.2}
         >
            <IonHeader>
               <IonToolbar>
                  <IonButtons slot="start">
                     <IonButton className='text-white' onClick={() => setOpen(false)}>Cancelar</IonButton>
                  </IonButtons>

                  <IonTitle style={{ textAlign: 'center' }}>Filtro</IonTitle>

                  <IonButtons slot="end">
                     <IonButton className='text-white' strong={true} onClick={() => applyFilter()}>Aplicar</IonButton>
                  </IonButtons>
               </IonToolbar>
            </IonHeader>

            <IonContent>

               {/* Formulário de filtro */}
               <Formik
                  innerRef={formRef}
                  initialValues={filter}
                  onSubmit={submit}
                  validationSchema={
                     Yup.object().shape({
                        date: Yup.date().required(),
                        stores: Yup.array(),
                        buyers: Yup.array()
                     })
                  }
               >
                  {(propsForm) => (
                     <Form id='my-form'>
                        <IonList>
                           <IonItem>
                              <IonLabel>Data :</IonLabel>
                              <IonDatetimeButton datetime="datetimeStart" />

                              <IonModal keepContentsMounted={true}>
                                 <IonDatetime
                                    id="datetimeStart"
                                    name="date"
                                    onIonChange={propsForm.handleChange}
                                    onIonBlur={propsForm.handleBlur}
                                    value={propsForm.values.date}
                                    locale="pt-BR"
                                    presentation='month-year'
                                    showDefaultButtons={true}
                                    style={{ width: '100%' }}
                                 />
                              </IonModal>
                           </IonItem>


                           {filter.aggregationType === 'STORES' &&
                              <>
                                 <IonItem style={{ marginRight: '1em' }}>
                                    <IonLabel>Unidades</IonLabel>
                                    <IonSelect
                                       value={propsForm.values.stores}
                                       name='stores'
                                       placeholder="Todas as lojas"
                                       compareWith={compareStoreWith}
                                       onIonChange={propsForm.handleChange}
                                       onIonBlur={propsForm.handleBlur}
                                       multiple={true}
                                    >
                                       {allStores.map((store) => (
                                          <IonSelectOption key={store.id} value={store}>
                                             {store.name}
                                          </IonSelectOption>
                                       ))}
                                    </IonSelect>
                                 </IonItem>
                              </>
                           }

                           {filter.aggregationType === 'BUYERS' &&
                              <IonItem style={{ marginRight: '1em' }}>
                                 <IonLabel>Compradores</IonLabel>
                                 <IonSelect
                                    value={propsForm.values.buyers}
                                    name='buyers'
                                    placeholder="Todas os compradores"
                                    compareWith={compareStoreWith}
                                    onIonChange={propsForm.handleChange}
                                    onIonBlur={propsForm.handleBlur}
                                    multiple={true}
                                 >
                                    {allBuyers.map((store) => (
                                       <IonSelectOption key={store.id} value={store}>
                                          {store.name}
                                       </IonSelectOption>
                                    ))}
                                 </IonSelect>
                              </IonItem>
                           }

                           <IonToolbar className='filter-white'>
                              <IonButtons slot="end" style={{ marginRight: '2em' }}>
                                 <IonButton onClick={() => { propsForm.setFieldValue(filter.aggregationType === 'BUYERS' ? 'buyers' : 'stores', []) }} >
                                    <IonIcon icon='square-outline' />
                                 </IonButton>
                                 <IonButton onClick={() => { propsForm.setFieldValue(filter.aggregationType === 'BUYERS' ? 'buyers' : 'stores', allStores) }} >
                                    <IonIcon icon='checkmark-done-outline' />
                                 </IonButton>
                              </IonButtons>
                           </IonToolbar>
                        </IonList>
                     </Form>
                  )}
               </Formik>

            </IonContent>
         </IonModal>
      </>
   );
};

export default Filter;