import { GoalResponse as GoalResponseItem } from "../../dto/goal/store-response";
import { Organization } from "../../dto/organization";
import { Store } from "../../dto/store/store";
import httpClient from "../../service/api";
import { getCurrentOrganization } from "../../service/session";
import { defaultDateStartFilter } from "../../utils/format-util";

export declare type AggregationType = 'STORES' | 'BUYERS'

export class GoalFilter {
  date: string = defaultDateStartFilter().toISOString()
  stores: Store[] = []
  buyers: Store[] = []
  aggregationType: AggregationType = 'STORES'
}

export class GoalResponse {

  dataGridList: GoalResponseItem[] = []

  //Venda Meta
  salesGoalValueMt: number = 0
  //Venda Realizado
  totalSaleValueMt: number = 0
  //Venda Atingimento
  salesAchievementPercentMt: number = 0
  //Venda tendência
  saleTrendValueMt: number = 0

  //Venda Meta
  totalMarginGoalPercentMt: number = 0
  //Margem Realizado
  totalMarginPercentMt: number = 0
  //Venda Atingimento
  marginAchievementPercentMt: number = 0
  //Venda markdown
  markDownPercentMt: number = 0
}

export class GoalData {
  filter: GoalFilter = new GoalFilter()

  date?: Date

  dataResponse?: GoalResponse

  categories: number[] = []

  serieAchievement: number[] = []
  serieSalesGoal: number[] = []
  serieSales: number[] = []

  maxSale: number = 0
  maxAtingimento: number = 0
}

export class ChartContext {
  chart?:ApexCharts;
  widthPxItem: number = 0
  divScrollWidthPercent?: number = 0
  maxScrollWidthPx?: number = 0
  itensPorTela: number = 0
  ionRangeMax?: number = 0
}

export const findData = async (filter: GoalFilter): Promise<GoalData> => {

  const org: Organization = await getCurrentOrganization()
  const f = {
    date: filter.date,
    aggregationType: filter.aggregationType,
    itens: filter.aggregationType === 'STORES' ? filter.stores : filter.buyers,
    organizationIdAtr: org.id
  }
  const response = await httpClient.post('/api/goal/process-period', f);
  const goalResponse: GoalResponse = response.data

  const data = new GoalData()
  data.date = new Date()
  data.filter = filter
  data.dataResponse = goalResponse

  //GRÁFICO.
  data.categories = goalResponse.dataGridList.map(item => item.id!)
  data.serieAchievement = goalResponse.dataGridList.map(item => item.salesAchievementPercentMt)
  data.serieSalesGoal = goalResponse.dataGridList.map(item => item.salesGoalValueMt)
  data.serieSales = goalResponse.dataGridList.map(item => item.totalSaleValueMt)

  //Cacula o maior valor do gráfico.
  const maxSale = Math.max.apply(null, data.serieSales)
  const maxSalesGoal = Math.max.apply(null, data.serieSalesGoal)
  const maxAchievement = Math.max.apply(null, data.serieAchievement)
  
  data.maxSale = Math.max.apply(null, [maxSale, maxSalesGoal, maxAchievement]) * 1.05
  data.maxAtingimento = maxAchievement * 1.05

  return data
}