import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { DivContainer, DivLegend } from './style';

interface Param {
  series: ApexAxisChartSeries
  options: ApexOptions
  height?: number
  widthPxItem?: number
  maxWidth?: number
  paddingRightAux?: string
  chatHeaderWidth?: number
  type?: "line" | "area" | "bar" | "histogram" | "pie" | "donut" | "radialBar" | "scatter" | "bubble" | "heatmap" | "treemap" | "boxPlot" | "candlestick" | "radar" | "polarArea" | "rangeBar"
}
const Chart: React.FC<Param> = ({series, options, height = 300, widthPxItem, paddingRightAux, chatHeaderWidth = 55, type, maxWidth}) => {

  const countItens = series[0].data.length 

  let widthItens;
  let width : any = '100%'
  if (widthPxItem && maxWidth) {
    widthItens = countItens * widthPxItem
    if (widthItens - 40 > maxWidth) {
      width = widthItens + 'px'
    }
  }


  if ('100%' === width) {
    return <div>
      <ReactApexChart type={type} options={options} series={series} height={height + 'px'} width={width} />
    </div>
           
  }

  const yaxis : ApexYAxis = options.yaxis as ApexYAxis;
  const labelsY = yaxis.labels;

  // if (options.grid) {
  //   options.grid!.padding = {left : 25}
  // } else {
  //   options.grid = {padding : {left : 25}}
  // }

  const optionsData : ApexOptions = {
    ...options,
    //Esconde a legenda das series, essa legenda estará no chartHeader.
    legend: {
      show: false
    },
    yaxis: {
      ...yaxis,
      labels: {
        ...labelsY,
        //Esconde a legenda da esquerda (yaxis), essa legenda estará no chartHeader
        show: false,
      }
    },
    chart: {
      ...options.chart,
      //Para sobrepor o gráfico de baixo.
      background: 'white'
    },
    tooltip: {
      enabled: false
   }
  }

  const optionsHeader : ApexOptions = {
    ...options,
    legend: {
      show: false
    },
    yaxis: {
      ...yaxis,
      labels: {
        ...labelsY,
        minWidth: 50,
        maxWidth: 50
      }
    }
  }

  // Legenda
  const seriesLegend = series.map(i => {return {...i, data: [null]}})
  const optionsLegend : ApexOptions = {
    chart: {
      toolbar: {
        show: false,  
      },
      background: 'white'

    },
    stroke : {
      show: false
    },
    tooltip: {
      enabled: false
    },
    legend: {
      show: true,
      height: 20,
      
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    markers: {
      showNullDataPoints: false
    },
    yaxis: {
      labels: {
        show: false,

      },
    },
    xaxis: {
      labels: {
        show: false,
      }
    },
  }

 /* Gráfico com o cabeçãlho  */
  return ( 
    <DivContainer> 
      <div style={{ width: '100%', height: (height + 10) + 'px', }}> 

        <div style={{ width: '100%', position: 'absolute' }}>
          <ReactApexChart type={type} options={optionsHeader} series={series} height={height + 'px'} width={width}/>
        </div>

        {/* Gráfico com os dados  */}
        <div style={{ width: '100%', position: 'absolute'
        , paddingLeft: chatHeaderWidth + 'px'
          // Margim para corrigir um pedação do grid que ficou pra fora.
          , paddingRight: paddingRightAux ? paddingRightAux : '0px'
          }}>
          
          <div className='scroll'>
            <div className='scroll-container' style={{ height: height + 'px'
              // Margem para aproximar a primeira barra ao label do eicho y.
              , marginLeft: '-16px'
              }}>

              <ReactApexChart type={type} options={optionsData} series={series} height={height+ 'px'} width={width}/>
            </div>
          </div>

        </div>
      </div>
      
      {/* Legenda */} 
      <DivLegend > 
          <ReactApexChart options={optionsLegend} series={seriesLegend} height={'20px'} width='100%'/>
      </DivLegend>
    </DivContainer>
  );
}

export default Chart;