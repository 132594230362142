export interface Organization {
  id?: number
  name?: string
}

export const compareOrganizationWith = (o1: Organization, o2: Organization) => {
  if (!o1 || !o2) {
    return o1 === o2
  }

  return o1.id === o2.id
}