import { intToStrg } from "../../utils/format-util"
import { DivH } from "../divh/styled"

interface Param {
   title: string
   value: number
   isShow?: boolean
}

const UnitDetails: React.FC<Param> = ({ title, value, isShow = true }) => {
   return (<>
      {isShow && (
         <DivH style={{ color: '#888' }}>
            <p style={{ fontSize: '0.7em', marginRight: '0.7em' }}>{title}</p>
            <p style={{ fontSize: '0.7em' }}>{intToStrg(value)}</p>
         </DivH>)
      }
   </>)
}

export default UnitDetails

