import { IonContent, IonHeader, IonIcon } from "@ionic/react";
import { DivH } from "../../../../../components/divh/styled";
import Donut from "../../../../../components/donut";
import MoneySymbol from "../../../../../components/money-symbol";
import PercentSymbol from "../../../../../components/percent-symbol";
import SaleDetailsModal from "../../../../../components/sale-details-modal";
import { MovProductsResponse } from "../../../../../dto/mov-produtos/mov-produtos-response";

export interface Paran {
    closeModal : Function
    item: MovProductsResponse
 }

const ModalSalesDetail: React.FC<Paran> = ({closeModal, item}) => {

   return <>
      <IonHeader style={{position: 'static'}}>
         <div style={{textAlign: 'end', padding: '1em 0.8em 0 1.3em'}}>
            <IonIcon icon='close-outline' style={{color: 'red', fontSize: '1.8em'}} onClick={() => closeModal()} />
         </div>
         <DivH style={{padding: '0 1.3em 0 1em'}}>
            <Donut valuePercent={item.totalSalePercentMt} size={80} strokeWidth={7} />
            <div>
               <p style={{fontSize: '1.2em', fontWeight: '600', color: '#1096df', paddingBottom: '0.3em'}}>Dpto: {item.label}</p>
               
               <p style={{fontSize: '1em', fontWeight: '600'}}>
                  <MoneySymbol value={item.totalSaleValueMt}/>  
               </p>
            </div>
         </DivH>
      </IonHeader>

      <IonContent>
         <div style={{padding: '0.5em 1.5em 0 1.7em'}}>  
            <SaleDetailsModal title='Lucro' value={item.totalProfitValueMt} />
            <DivH style={{fontSize: '1em', paddingBottom: '0.5em'}}>
               <p style={{color: 'var(--color-primary)'}}>Margem </p>
               <p style={{color: '#888'}}> 
               <PercentSymbol value={item.totalMarginMt} ></PercentSymbol>
               </p>
            </DivH>


            <SaleDetailsModal title='Custo total' value={item.costValueMt}/>
            <SaleDetailsModal title='Autosserviço' value={item.pdvSaleValueMt}/>
            <SaleDetailsModal title='DC' value={item.flexSaleValueMt}/>
            {/* <SaleDetailsModal title='Venda Oferta' value={item.offerValueMt}/> */}
            {/* <DivH style={{fontSize: '1em', paddingBottom: '0.5em'}}>
               <p style={{color: 'var(--color-primary)'}}>Metas</p>
               <p style={{color: '#888'}}>{item.goalPercentMt}</p>
               <p style={{color: '#888'}}>{item.goalValueMt}</p>
            </DivH> */}
            
            <SaleDetailsModal title='Perdas' value={item.lossGoalValueMt}/>
            {/* <DivH style={{fontSize: '1em', paddingBottom: '0.5em'}}>
               <p style={{color: 'var(--color-primary)'}}>Teto Perdas</p>
               <p style={{color: '#888'}}>{item.lossGoalValuePercentMt}</p>
               <p style={{color: '#888'}}>{item.lossGoalValueMt}</p>
            </DivH> */}

            <SaleDetailsModal title='Devoluções de Venda' value={item.saleDevValueMt}/>
            <SaleDetailsModal title='Descontos' value={item.descountValueMt}/>
            {/* <SaleDetailsModal title='Cancelamentos' value={item.cancellationValueMt}/> */}
            <SaleDetailsModal title='Acréscimos' value={item.additionValueMt}/>
         </div>
      </IonContent>
   </>
}

export default ModalSalesDetail;