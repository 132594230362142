import moment from "moment"

export const defaultDateStartFilter = () : Date => {
    // return new Date('2022-10-01T00:00:00')
    return new Date()
}

export const defaultDateEndFilter = () : Date => {
    // return new Date('2022-10-31T00:00:00')
    return new Date()
}

export const doubleToStr = (value: any) => {
    if (value) {
        const result = Number(value).toLocaleString('pt-BR', {  currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 })
        return result
    } 
    
    return '0,00'
}

export const intToStrg = (value: number) => {
    if (value) {
        return Math.round(value).toLocaleString('pt-BR', {  currency: 'BRL'})
    } 
    return '0'
}

export const dateTimeToStrg = (date: Date) => {
    if (date) {
        return moment(date).format('DD/MM/YY hh:mm')
    }
    return ''
}

export const timeToStrg = (date: Date | undefined) => {
    if (date) {
        return moment(date).format('DD/MM hh:mm:ss')
    }
    return 'undefined'
}

export const dateToLabel = (date: Date) => {
    if (date) {
        return moment(date).format('DD/MM/YY')
    }
    return ''
}

export const dateToStr = (date: Date) => {
    if (date) {
        return moment(date).format('YYYY-MM-DD')
    }
    return ''
}

export const convertHourLabel = (hour: string | undefined) => {
    if (!hour) return ''
    return (+hour <= 9 ? '0' + hour : hour) + 'h'
}

export const convertSaleLabel = (sale: number | undefined) => {
    if (!sale){ 
        return '0'
    } else if (sale >= 1_000_000 || sale <= -1_000_000) {
        return doubleToStr(sale / 1_000_000) + 'mi'
    } else if (sale >= 1_000 || sale <= -1_000){
        return doubleToStr(sale / 1_000) + 'mil'
    } else {
        return doubleToStr(sale)
    }
}

export const convertIntLabel = (sale: number | undefined) => {
    if (!sale) { 
        return '0'
    } else {
        return intToStrg(sale)
    }
}
export const convertDoubleLabel = (sale: number | undefined) => {
    if (!sale) { 
        return '0'
    } else {
        return doubleToStr(sale)
    }
}

// export const strISOToDateTimeStr = (date: string) : string | undefined => {
//     if (date) {
//         const strVet = date.split("T")
//         return strVet[0] + ' ' + strVet[1].split('-')[0]
//     }
// }

export const strISOToDateStr = (date: string) : string | undefined => {
    if (date) {
        const strVet = date.split('T')
        const numbers = strVet[0].split('-')
        return numbers[0]
    }
}