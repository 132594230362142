import { User } from "../../../dto/user"
import httpClient from "../../../service/api"

export const findData = async () : Promise<User[]> => {
  const response: any = await httpClient.get("/api/user")
  return response.data
}

export const deleteUser = async (user:User) : Promise<void> => {
  await httpClient.get(`/api/user/delete/${user.id}`)
}