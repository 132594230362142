import { IonIcon } from '@ionic/react';
import { Container, IonIconEl } from './styled';

interface Param {
    countIcons?: number;
} 

const UpArrow: React.FC<Param> = ({countIcons = 4}) => {

    const vetIcons = [];
    
    for (let x = 1; x < countIcons; x++) {
        vetIcons.push(<IonIconEl key={x} icon="chevron-up-outline" />)
    }

    return (
        <Container>
            <IonIcon icon="chevron-up-outline" />
            {vetIcons}
        </Container>
    );
}

export default UpArrow;