import styled from "styled-components";

export const ContentNoDataFoundEl = styled.div`
   height: 100%; 
   display: flex; 
   align-items: center; 
   justify-content: center; 
   font-size: 1.5em; 
   font-weight: bold; 
   text-align: center;
`