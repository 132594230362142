// import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation';
import { IonIcon } from '@ionic/react';
import { FullScreenButtonEl } from './styled';

interface Param {
    handle: any;
}

const FullScreenButton: React.FC<Param> = ({ handle }) => {
    return (
        <FullScreenButtonEl
            onClick={() => {
                if (!handle.active) {
                    handle.enter()
                    // ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE)
                }
                else {
                    handle.exit()
                    // ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT)
                }
            }}
        >
            <IonIcon style={{opacity: "0.6"}} icon={handle.active ? "contract-outline" : "expand-outline"}/> 
        </FullScreenButtonEl>
    );
}

export default FullScreenButton