import { Donut as DonutT } from 'react-donut-component';
import { doubleToStr, intToStrg } from '../../utils/format-util';
import { DonutValueEl } from './style';

interface dataProps {
  valuePercent: number;
  className?: string;
  style?: any;
  ref?: any;
  size?: number;
  strokeWidth?: number;
}

const Donut: React.FC<dataProps> = ({valuePercent, className, style, ref, size=50, strokeWidth=3}) => {

  const colorStroke = '#1096df';
  const colorTrack = '#98e7ff';

  const formatValuePercent = () => {
    if (valuePercent < 10 && valuePercent > -10 && valuePercent !== 0) {
      return doubleToStr(valuePercent)
    } else {
      return intToStrg(valuePercent)
    }
  }

  return (
    <div className={className} style={style} ref={ref}>
      <DonutT
      size={size}
      styleTrack={{ strokeWidth: strokeWidth, stroke: colorTrack }}
      styleIndicator={{ stroke: colorStroke, strokeLinecap: 'round', strokeWidth: strokeWidth}}
    >
      <DonutValueEl valuePercent={valuePercent}

        styleSymbol={{ fontSize: valuePercent >= 100 ? '0.3em' : '0.6em'}}
      >
        {formatValuePercent()}
      </DonutValueEl>  
    </DonutT>
    </div>
  )
}

export default Donut;