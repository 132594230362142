import { IonIcon } from "@ionic/react";
import styled from "styled-components";

export const Container = styled.div`
    width: 0.9em;
    line-height: 0;
`;

export const IonIconEl = styled(IonIcon)`
    margin-top: -0.7em;
`;