import { User } from "../../../dto/user"
import httpClient from "../../../service/api"

export const save = async (user:User) : Promise<void> => {
  if (user.id) {
    await httpClient.post('/api/user/update', user)
  } else {
    await httpClient.post('/api/user/create', user)
  }
}

