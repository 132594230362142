import { IonProgressBar } from "@ionic/react";
import styled from "styled-components";

export const ProgressBarEl = styled(IonProgressBar)`
   margin-top: 5px;
   width: 80px;
`

export const H2LabelTotal = styled.h2`
  font-size: 0.8em;
  text-align: right;
  margin-right: 26px;
  font-weight: bold;
`

export const H2LabelStoreName = styled.h2`
  color : #333;
  margin : 0px
`

export const LabelHeader = styled.p`
  font-size: 0.7em;
  color: #333;
`

export const ValueHeader = styled.p`
  font-size: 0.8em;
`