import Card from "../../../../components/card";
import { DivH } from "../../../../components/divh/styled";
import IndicatorPercentValue from "../../../../components/indicator-percent-value";
import MoneySymbol from "../../../../components/money-symbol";
import { PeriodRefDto } from "../../../../dto/home/period-value-response";

export interface Paran {
  value: PeriodRefDto;
}

const CardRefSale: React.FC<Paran> = ({value}) => {
  return (
    <Card>
      <DivH>
        <IndicatorPercentValue percent={value.totalSalePercentRefMt} />
        <div>
          <h4>Venda total em {value.label}</h4> 
          <h3><MoneySymbol value={value.totalSaleValueMt} /></h3>
        </div>
      </DivH>
    </Card>
  )
};

export default CardRefSale;
