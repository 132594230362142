export interface Store {
    id: number;
    name: string;
}

export const compareStoreWith = (o1: Store, o2: Store) => {
    if (!o1 || !o2) {
      return o1 === o2;
    }
  
    // if (Array.isArray(o2)) {
    //   return o2.some((o) => o.id === o1.id);
    // }
    return o1.id === o2.id;
  };