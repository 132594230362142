import styled from "styled-components";

export const ModalButtonEl = styled.button`
   color: var(--ion-color-primary);
   text-transform: uppercase;
   font-size: 0.9em;
   font-weight: 500;
   text-align: end;
   line-height: 3em;
   background: none;
`