import { IonIcon } from '@ionic/react';

interface Params {
    title?: string,
    icon?: 'clock' | 'store',
}

const IconTitle: React.FC<Params> = ({title, icon}) => {
    let iconSet = "";

    if(icon === 'clock') {
        iconSet = "time-outline";
    }
    else if(icon === 'store') {
        iconSet = "storefront-outline";
    }

    return (
        <div style={{display: "flex", alignItems: "center", gap: "3px"}}>
            <IonIcon icon={iconSet} style={{fontSize: "1.8em", color: "var(--color-primary)"}} />
 
            <h2>{title}</h2>
        </div>
    );
}

export default IconTitle;