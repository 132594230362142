import { MovProductsResponse } from "../../../../../dto/mov-produtos/mov-produtos-response";
import httpClient from "../../../../../service/api";
import { MovProductsFilter } from "../../../service";

export class MovProductsModalFilter extends MovProductsFilter {
    idAggregation?: number
}

export class MovProductsModalData {
    dataGridList?: MovProductsResponse[]
}

export const findData = async (filter: MovProductsModalFilter) : Promise<MovProductsModalData> => {
    const response = await httpClient.post('/api/product-movement/products', filter)

    const data = new MovProductsModalData()
    data.dataGridList = response.data

    return data
}