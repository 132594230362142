import { Organization } from "./organization"

export class User {
  id?: number
  name?: string
  email?: string
  password?: string
  passwordConfirm?: string
  isAdmin? : boolean 
  organizations? : Organization[]
}
  