import styled from "styled-components";

export const StyledContent = styled.div`

   .card-content-md {
      font-size: 22px;
   }
   
   h3 {
      font-weight: 570 ;
      font-size: 0.8em;
   }

   h4 {
      font-size: 0.7em;
   }
    
   ion-col {
      display: grid;
   }

   @media only screen and (max-width: 600px) and (min-width: 460px) {
      .card-content-md {
         font-size: 20px;
      }
   }

   @media only screen and (max-width: 460px) and (min-width: 400px) {
      .card-content-md {
         font-size: 17px;
      }
   }

   @media only screen and (max-width: 400px) {
      body {
         font-size: 15px;
      }

      .card-content-md {
         font-size: 15px;
      }

      .goal-h2-left {
         margin-left : -10px;
      }
      
      .goal-h2-right {
         margin-right : -10px;
      }
   }

   h2 {
      font-size: 1em;
      font-weight: 600;
      color: #000000c9;
   }
    
`;