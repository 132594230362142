import { doubleToStr } from "../../utils/format-util";

interface Param{
    value: number;
}

const PercentSymbol: React.FC<Param> = ({value}) => {
    return(
        <>
            {doubleToStr(value)}

            <span style={{fontSize: "0.7em", fontWeight: "400", marginLeft: "0.2em"}}>
                {"%"}
            </span>
        </>
    );
}

export default PercentSymbol;