import Card from "../../../../components/card";
import { DivH } from "../../../../components/divh/styled";
import MoneySymbol from "../../../../components/money-symbol";
import PercentSymbol from "../../../../components/percent-symbol";
import { PeriodRefDto, PeriodValueDto } from "../../../../dto/home/period-value-response";

export interface Paran {
  title: string;
  value: PeriodValueDto;
  valueRef: PeriodRefDto;
}

const CardProfit: React.FC<Paran> = ({title, value, valueRef}) => {

  const getColor = (value:number) => {
    return value > 0 ? "var(--color-very-good)" : "var(--color-very-bad)";
  }

  return (
    <Card>
      <DivH>
        <div style={{color: getColor(value.totalMarginMt), fontSize: "0.8em", textAlign: "left"}}>
          <p style={{fontSize: "1em"}}>Margem</p>
          <PercentSymbol value={value.totalMarginMt} />
        </div>
        <div>
          <h4>{title}</h4> 
          <h3> <MoneySymbol value={value.totalProfitValueMt} /></h3>
        </div>
      </DivH>

      <DivH>
        <div style={{color: getColor(valueRef.totalProfitPercentRefMt), fontSize: "0.8em", textAlign: "left"}}>
          <PercentSymbol value={valueRef.totalProfitPercentRefMt} />
        </div>
        <div>
          <h4>{valueRef.label}</h4> 
          <h3> <MoneySymbol value={valueRef.totalProfitValueMt} /></h3>
        </div>
      </DivH>
    </Card>
  )
};

export default CardProfit;
