import axios, { AxiosInstance } from 'axios'
import { getToken, getTokenUpdate, logout, updateToken } from './session'

// axios.defaults.timeout = 10000

const httpClient: AxiosInstance = axios.create({
  // Dev
  // baseURL: 'http://localhost:8080/',
  // baseURL: 'http://192.168.100.110:8080/',
  // Prod
  baseURL: 'https://appbi.martminas.com.br:443',  
  headers: {
    // "Content-Type": "application/json"
  }
})

httpClient.interceptors.request.use(async (config: any) => {
  const token = await getToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  } else {
    const tokenUpdate = await getTokenUpdate()
    if (tokenUpdate) {
      config.headers.Authorization = `Bearer ${tokenUpdate}`
    }
  }

  return config
},  (error) => {
  return Promise.reject(error)
})

httpClient.interceptors.response.use(
  (res) => {
    return res;
  }, async (err) => {
    const originalConfig = err.config

    const tokenUpdate = await getTokenUpdate()

  if (originalConfig.url !== "/api/auth/refreshToken" 
    && err.response
    && err.response.status === 401 
    && tokenUpdate) {

      //Recuperar o token.
      try {

        await updateToken(null)
        const response: any = await httpClient.get('/api/auth/refreshToken')
        const { token } = response.data

        //Atualiza o novo token.
        await updateToken(token)

        //Refaz a chamada que ocorreu o erro ao token espirar.
        return httpClient(originalConfig)

      } catch (_error:any) {
        //Caso retorne 401 significa que o refreshToken também espirou.
        //Se ainda tem o token, reiniciamos o app para fazer o logout.
        if (_error!.response!.status === 401 && tokenUpdate) {
          logout()
          //Recarrega a página para voltar ao login.
          window.location.reload()
        }

        return Promise.reject(_error)
      }
    }

    return Promise.reject(err)
  }
);



export default httpClient