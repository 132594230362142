import { ApexOptions } from 'apexcharts';
import { useContext, useEffect, useRef, useState } from 'react';
import CardFullScreen from '../../../../components/card-full-screen';
import Chart from '../../../../components/chart';
import IconTitle from '../../../../components/icon-title';
import { convertSaleLabel } from '../../../../utils/format-util';
import HomeContext from '../../context';
import { DataStoresBySalesChart, findData } from './service';
import { Organization } from '../../../../dto/organization';
import { getCurrentOrganization } from '../../../../service/session';

const ChartSalesByStores: React.FC = () =>{
  const divContainer = useRef(null as any)
  const { dataHome } = useContext(HomeContext);
  const [dataChart, setDataChart] = useState({} as DataStoresBySalesChart)

  useEffect(() => {
    
    const find = async () => {
      const org: Organization = await getCurrentOrganization()

      const dataChart = await findData( {...dataHome.filter, organizationIdAtr: org.id})
      setDataChart(dataChart)
    }

    find()
  }, [dataHome])

  const buildChart = () => { 

    const options : ApexOptions = {
      chart: {
        toolbar: {
          show: false,  
        },
        background: 'white',
      },
      xaxis: {
        categories: dataChart.store,
        labels: {
          rotate: -90,
        }
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: 0,
        max: dataChart.maxSale,
        labels: {
          show: true,
          formatter: convertSaleLabel,
        }
      },
    }
    
    const series: ApexAxisChartSeries = [
      {
        name: 'Autosserviço',
        data: dataChart.pdvSale!,
        type: 'bar',
      
      },
      {
        name: 'DC',
        data: dataChart.directSale!,
        type: 'bar',
      }
    ]

    return <CardFullScreen>
      <IconTitle title="Vendas por loja" icon="clock" />
      <Chart series={series} options={options} paddingRightAux='14px' height={200} maxWidth={divContainer.current.offsetWidth} widthPxItem={15}/>
    </CardFullScreen>
  }

  return (
    <div ref={divContainer}>
    {dataChart.dataGridList &&
      buildChart()
    }
  </div>

  );
}

export default ChartSalesByStores;