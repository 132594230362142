import { PaymentMethodsResponse } from "../../dto/payment-methods/payment-methods-response";
import { Store } from "../../dto/store/store";
import httpClient from "../../service/api";
import { defaultDateEndFilter, defaultDateStartFilter } from "../../utils/format-util";

export declare type AggregationType = 'FINALIZADORA' | 'SPECIAL_OPERATIONS' |'FORM_OF_SALE'

export class PaymentMethodsFilter {
  dateStart: string = defaultDateStartFilter().toISOString()
  dateEnd: string = defaultDateEndFilter().toISOString()

  aggregationType: AggregationType = 'FINALIZADORA'
  stores: Store[] = []
}

export class PaymentMethodsData {
  filter : PaymentMethodsFilter = new PaymentMethodsFilter()
  dataGridList? : PaymentMethodsResponse[]
  date? : Date
  
  categorieOperatorIdAtr: number [] = []
  serieTotalSaleValueMt : number [] = []

  categorieLabel : string[] = []
}

export const findData = async (filter: PaymentMethodsFilter) : Promise<PaymentMethodsData> => {
  const response = await httpClient.post('/api/payment/process-period', filter);
  const dataResponse : PaymentMethodsResponse[]  = response.data

  const data = new PaymentMethodsData()
  data.filter = filter
  data.dataGridList = dataResponse
  data.date = new Date()

  data.serieTotalSaleValueMt = dataResponse.map(item => item.totalSaleValueMt)
  // data.serieOfferValueMt = dataResponse.map(item => item.offerValueMt)
  // data.serieFlexSaleValueMt = dataResponse.map(item => item.flexSaleValueMt)
  // data.serieGoalValueMt = dataResponse.map(item => item.goalValueMt)
  // data.serieLossValueMt = dataResponse.map(item => item.lossValueMt)
  // data.serieCostValueMt = dataResponse.map(item => item.costValueMt)
  data.categorieLabel = dataResponse.map(item => item.id + "")

  return data;
}