import { IonCardContent } from '@ionic/react';
import React from 'react';
import 'react-apexcharts';
import { CardEl } from './styled';

const Card: React.FC = ({children}) => {
  return (
    <CardEl>
        <IonCardContent>
            {children}
        </IonCardContent>
    </CardEl>
  )
};

export default Card;