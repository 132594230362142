import Card from "../../../../components/card";
import { DivH } from "../../../../components/divh/styled";
import IndicatorPercentValue from "../../../../components/indicator-percent-value";
import { PeriodRefDto, PeriodValueDto } from "../../../../dto/home/period-value-response";
import { intToStrg } from "../../../../utils/format-util";

export interface Paran {
  title: string;
  value: PeriodValueDto;
  valueRef: PeriodRefDto;
}

const CardClientAmount: React.FC<Paran> = ({title, value, valueRef}) => {
  return (
    <Card>
      <DivH>
        <IndicatorPercentValue percent={valueRef.amountClientPercentRef} />

        <div>
          <div>
            <h4>{title}</h4> 
            <h3>{intToStrg(value.amountClientMt)}</h3>
          </div>
          <div>
            <h4>{valueRef.label}</h4> 
            <h3>{intToStrg(valueRef.amountClientMt)}</h3>
          </div>
        </div>
      </DivH>
    </Card>
  )
};

export default CardClientAmount;
