import Card from '../../../../components/card';
import { DivH } from '../../../../components/divh/styled';
import IndicatorPercentValue from '../../../../components/indicator-percent-value';
import MoneySymbol from '../../../../components/money-symbol';
import { PeriodRefDto, PeriodValueDto } from '../../../../dto/home/period-value-response';

export interface Paran {
  title: string;
  value: PeriodValueDto;
  valueRef: PeriodRefDto;
}

const CardMediumTicket: React.FC<Paran> = ({title, value, valueRef}) => {
  return (
    <Card>
      <DivH>
        <IndicatorPercentValue percent={valueRef.averageTicketPercentRef} />

        <div>
          <div>
            <h4>{title}</h4> 
            <h3><MoneySymbol value={value.averageTicketAtr} /></h3>
          </div>
          <div>
            <h4>{valueRef.label}</h4> 
            <h3><MoneySymbol value={valueRef.averageTicketAtr} /></h3>
          </div>
        </div>
      </DivH>
    </Card>
  )
};

export default CardMediumTicket;
