import { doubleToStr, intToStrg } from "../../utils/format-util";

interface Param {
    label: string;
    value: number;
    fontSize?: string;
    isShow?:boolean;
}

const LabelUnitSymbol: React.ElementType<Param> = ({label, value = 0, fontSize = "0.7em", isShow=true}) => {
    return isShow ? (
            <> 
            <span style={{fontSize: '0.7em', marginRight: '0.8em'}}>{label}</span>

                {/* <span style={{fontSize: fontSize, fontWeight: "400", marginRight: "0.2em", display: "inline-block"}}>
                    {"R$"}
                </span> */}
                <span style={{display: "inline-block"}}>
                    {intToStrg(value)}
                </span>
            </>
        ) : <></>
    ;
}

export default LabelUnitSymbol;