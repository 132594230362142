import { AccordionGroupCustomEvent, IonAccordion, IonAccordionGroup, IonButtons, IonContent, IonHeader, IonItem, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { ApexOptions } from 'apexcharts';
import React, { useRef, useState } from 'react';
import Chart from '../../components/chart';
import ContentNoDataFound from '../../components/content-no-data-found';
import { DivHMaxWidth, DivMaxWidth } from '../../components/div/styled';
import { DivH } from '../../components/divh/styled';
import Donut from '../../components/donut';
import IndicatorPercentValue from '../../components/indicator-percent-value';
import Loading from '../../components/loading';
import MoneySymbol from '../../components/money-symbol';
import RefreshData from '../../components/refresh-data';
import SaleDetails from '../../components/sale-details';
import { StoreResponse } from '../../dto/store/store-response';
import '../../theme/styled';
import { convertSaleLabel } from '../../utils/format-util';
import Filter from './filter';
import { StoreData, findData } from './service';
import { H2LabelStoreName, H2LabelTotal, LabelHeader, ProgressBarEl, ValueHeader } from './styled';
import UnitDetails from '../../components/unit-details';

const Stores: React.FC = () => {
  const divContainer = useRef(null as any);
  const [data, setData] = useState(new StoreData())
  //Sub state
  const isLoadingData = !data.dataGridList
  const isDataEmpty = data.dataGridList && data.dataGridList.length === 0

  const find = async (filter: any) => {
    const datachart : StoreData = await findData(filter)
    setData(datachart)
  }

  const buildContent = () => {
    if (isDataEmpty) {
      return <ContentNoDataFound />
    }

    return <>
      {buildTable()}
    </>
  }

  const buildChart = () => {
    if (isDataEmpty) {
      return <></>
    }

    const buildOptions : ApexOptions = {
      legend: {
        show: true
      },
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false, 
        },
      },
      stroke: {
        width: [0, 0, 3, 3, 3],
        curve: 'straight',
      },
      xaxis: {
        categories: data.categoriesStores,
        labels: {
          rotate: -90,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        max: data.maxSale,
        min: 0,
        labels: {
          show: true,
          formatter: convertSaleLabel,
        }
      },
    }
  
    const buildSeries = [
      {
        name: 'Venda',
        type: 'bar',
        data: data.totalSaleValueMt,
      },
      {
        name: 'DC',
        type: 'bar',
        data: data.serieDirectSales,
      },
      // {
      //   name: 'Meta',
      //   type: 'line',
      //   data: data.serieGoal,
      // },
      // {
      //   name: 'Perdas',
      //   type: 'line',
      //   data: data.serieLosses,
      // },
      {
        name: 'Custo total',
        type: 'line',
        data: data.serieTotalCost,
      }
    ]

    return <Chart options={buildOptions} series={buildSeries} height={200} maxWidth={divContainer.current.offsetWidth} widthPxItem={15}/>
  }

  const buildTable = () => {
    return <IonAccordionGroup onIonChange={accordionGroupChange}>
        {
          data!.dataGridList!.map((item: StoreResponse) => {
            return (
              <IonAccordion className={'class-' + item.store?.id} id='accordionId' key={item.store?.id.toString()} value={item.store?.id.toString()}>
                
                {/* Header */}
                <IonItem slot="header" style={{ fontSize: '1em' , textAlign: 'center'}}>
                  
                  <DivMaxWidth style={{ width: '100%'}}>

                      <H2LabelStoreName>
                          {`${item.store?.name} `}
                      </H2LabelStoreName>

                      <DivH style={{ textAlign: 'left', alignItems: 'start', paddingBottom: '0.5em' }}>
                          <div>
                              <LabelHeader>Vendas</LabelHeader>

                              <ValueHeader>
                                  <MoneySymbol value={item.totalSaleValueMt} />
                              </ValueHeader>

                              <Donut className='donutClass' style={{display: 'none'}} valuePercent={item.totalSalePercentMt} /> 

                              <ProgressBarEl className='progressClass' value={item.totalSalePercentMt / 100} />
                          </div>

                          <div>
                              <LabelHeader>Ano Anterior</LabelHeader>
                              <ValueHeader> 
                                  <MoneySymbol value={item.totalSaleValueRefMt}/> 
                              </ValueHeader>

                              <IndicatorPercentValue percent={item.totalSalePercentRefMt}/>
                          </div>
                      </DivH> 

                  </DivMaxWidth>
                </IonItem>

                <div slot="content" style={{padding: '0 0 12px 24px', marginRight: '43px'}}>

                  <DivHMaxWidth>
                    <div>
                      <LabelHeader>Lucro</LabelHeader>

                      <ValueHeader>
                          <MoneySymbol value={item.totalProfitValueMt} />
                      </ValueHeader>

                      <Donut valuePercent={item.totalMarginMt}  />
                    </div>

                    <div>
                      <LabelHeader>Ano Anterior</LabelHeader>

                      <ValueHeader>
                          <MoneySymbol value={item.totalProfitValueRefMt} />
                      </ValueHeader>
                      
                      <IndicatorPercentValue percent={item.totalMarginRefMt} />
                    </div>
                  </DivHMaxWidth>

                  <DivMaxWidth>
                    <SaleDetails title='Custo total' value={item.costValueMt} />
                    <SaleDetails title='Autosserviço' value={item.pdvSaleValueMt} />
                    <SaleDetails title='DC' value={item.flexSaleValueMt} />
                    <SaleDetails title='Devoluções de venda' value={item.saleDevValueMt} />
                    <SaleDetails title='Perdas' value={item.lossValueMt} />
                    <SaleDetails title='Descontos' value={item.descountValueMt} />
                    <UnitDetails title='Cupons' value={item.amountClientMt} />
                    {/* <SaleDetails title='Cancelamentos' value={item.cancellationValueMt} /> */}
                  </DivMaxWidth>
                </div>
              </IonAccordion>
            )
          })
        }
      </IonAccordionGroup>
  }

  const accordionGroupChange = (ev: AccordionGroupCustomEvent) => {
    if (ev.detail.value) {
      const itemExpand = ev.target.getElementsByClassName('class-' + ev.detail.value)
      const dounta = itemExpand[0].getElementsByClassName('donutClass')
      dounta[0].setAttribute("style", "display:block;");
      
      const progress = itemExpand[0].getElementsByClassName('progressClass')
      progress[0].setAttribute('style', 'display:none;');
    }


    const itemExpand = ev.target.getElementsByClassName('accordion-expanded')
    if (itemExpand.length > 0) {
      const dounta = itemExpand[0].getElementsByClassName('donutClass')
      dounta[0].setAttribute("style", "display:none;");

      const progress = itemExpand[0].getElementsByClassName('progressClass')
      progress[0].setAttribute('style', 'display:block;');
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonTitle style={{ marginLeft: '1em' }}>Unidades</IonTitle>

          <IonButtons slot="end">
            <RefreshData data={data} exectUpdate={find} label='Stores'/>
            <Filter setData={setData} filter={data.filter} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div>
        {!isLoadingData && 
          <>
            {buildChart()}

            <H2LabelTotal>
              <MoneySymbol value={data.resultcalcTotalSaleValueMt} />
            </H2LabelTotal>
          </>
        }
      </div>

      <IonContent ref={divContainer}>
        {isLoadingData && 
          <Loading /> 
        }

        {!isLoadingData && 
          buildContent()
        }

      </IonContent>
    </IonPage>
  )
}

export default Stores;