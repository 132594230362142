import { IonButton } from "@ionic/react";
import styled from "styled-components";

export const ButtonOpenModal = styled(IonButton)`
    /* font-size: 25px;
    display: flex;
    align-items: center;
    background-color: transparent;
    margin-right: 12px; */
`
