import { IonAccordion, IonAccordionGroup, IonButtons, IonContent, IonFooter, IonHeader, IonItem, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { ApexOptions } from "apexcharts";
import { useRef, useState } from "react";
import Chart from "../../components/chart";
import ContentNoDataFound from "../../components/content-no-data-found";
import { DivHMaxWidth, DivMaxWidth } from "../../components/div/styled";
import { DivH } from "../../components/divh/styled";
import Donut from "../../components/donut";
import IconButton from "../../components/icon-button";
import Loading from "../../components/loading";
import MoneySymbol from "../../components/money-symbol";
import RefreshData from "../../components/refresh-data";
import SaleDetails from "../../components/sale-details";
import { PaymentMethodsResponse } from "../../dto/payment-methods/payment-methods-response";
import { convertSaleLabel, intToStrg } from "../../utils/format-util";
import Filter from "./filter";
import { PaymentMethodsData, findData } from "./service";

const PaymentMethods: React.FC = () => {
   const divContainer = useRef(null as any)
   const [data, setData] = useState(new PaymentMethodsData())

   const isLoadingData = !data.dataGridList
   const isDataEmpty = !isLoadingData && data.serieTotalSaleValueMt && data.dataGridList!.length === 0

   const find = async (filter: any) => {
      const datachart = await findData(filter)
      setData(datachart)
   }

   const buildContent = () => {
      if (isDataEmpty) {
         return <ContentNoDataFound />
      }

      return <>
         {buildTable()}
      </>
   }

   const buildChart = () => {
      if (isDataEmpty) {
         return <></>
      }
      const options: ApexOptions = {
         chart: {
            toolbar: {
               show: true,
               tools: {
                  zoom: false,
                  zoomin: true,
                  zoomout: true,
                  selection: false,
                  download: false,
                  pan: false,
                  reset: false,
               }
            },
         },
         xaxis: {
              categories: data.categorieLabel,
            labels: {
               rotate: -90,
            }
         },
         dataLabels: {
            enabled: false,
         },
         yaxis: {
            min:0,
            labels: {
               // show: false,
               formatter: convertSaleLabel,
            }
         }
      }

      const series = [
         {
            name: 'Venda Total',
            data: data.serieTotalSaleValueMt,

         }
      ]

      return <Chart options={options} series={series} height={200} maxWidth={divContainer.current.offsetWidth} widthPxItem={15} />
   }

   const buildTable = () => {
      return <IonAccordionGroup>
         {
            data.dataGridList!.map((item: PaymentMethodsResponse, index: number) => {
               return (
                  <IonAccordion key={index} value={index.toString()}>
                     <IonItem slot='header' style={{ fontSize: '1em' }}>
                        <DivHMaxWidth style={{ width: '100%', alignItems: 'center', paddingBottom: 0 }}>
                           <Donut valuePercent={item.totalSalePercentMt} style={{ padding: '0.5em 0 0.5em 0' }} />
                           <div>
                              <p style={{ fontSize: '0.9em', fontWeight: '600' }}>
                                 {`${item.id} - ${item.label}`}
                              </p>

                              <p style={{ fontSize: '0.9em' }}>
                                 <MoneySymbol value={item.totalSaleValueMt} />
                              </p>
                           </div>
                        </DivHMaxWidth>
                     </IonItem>

                     <div slot="content" style={{ padding: '0 0 12px 24px', marginRight: '43px' }} >
                        <DivMaxWidth>
                           <SaleDetails title='Ticket médio' value={item.averageTicketAtr} />
                           <DivH style={{color: '#888'}}>
                              <p style={{fontSize: '0.7em'}}>Qtde cliente</p>
                              <p style={{fontSize: '0.7em'}}>{intToStrg(item.amountClientMt)}</p>
                           </DivH>

                           <SaleDetails title='Descontos' value={item.descountValueMt} />
                           {/* <SaleDetails title='Cancelamentos' value={item.cancellationValueMt} /> */}
                           <SaleDetails title='Acréscimos' value={item.additionValueMt} />
                        </DivMaxWidth>
                     </div>
                  </IonAccordion>
               )
            })
         }
      </IonAccordionGroup>
   }

   const buildFooter = () => {

      const buttons = [
         {
            label: 'Finalizadoras',
            aggregation: 'FINALIZADORA',
            icon: 'business'
         }, {
         //    label: 'Op especiais',
         //    aggregation: 'SPECIAL_OPERATIONS',
         //    icon: 'grid'
         // }, {
            label: 'Formas de venda',
            aggregation: 'FORM_OF_SALE',
            icon: 'star'
         }
      ]

      return <IonFooter>
         <IonToolbar className='background-white'>
            <IonButtons style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', maxWidth: '25em', margin: '0 auto' }}>
               {buttons.map((button, index) => {
                  return <IconButton
                     key={index}
                     style={{ color: data.filter.aggregationType === button.aggregation ? 'var(--ion-color-primary)' : '' }}
                     title={button.label}
                     icon={button.icon as any}
                     onClick={() => { changeAggregation(button.aggregation) }}
                  />
               })}
            </IonButtons>
         </IonToolbar>
      </IonFooter>
   }

   const changeAggregation = (value: any) => {
      setData({
         filter: {
            ...data.filter,
            aggregationType: value
         }
      } as PaymentMethodsData)
   }

   return (
      <IonPage>
         <IonHeader>
            <IonToolbar>
               <IonButtons slot='start'>
                  <IonMenuButton />
               </IonButtons>

               <IonTitle style={{ marginLeft: '1em' }}>Forma de pagamento</IonTitle>

               <IonButtons slot='end'>
                  <RefreshData data={data} exectUpdate={find} label='Payment' />
                  <Filter setData={setData} filter={data.filter} />
               </IonButtons>

            </IonToolbar>
         </IonHeader>

         <div>
            {!isLoadingData &&
               buildChart()
            }
         </div>

         <IonContent ref={divContainer}>
            {isLoadingData &&
               <Loading />
            }

            {!isLoadingData &&
               buildContent()
            }
         </IonContent>

         {!isLoadingData &&
            buildFooter()
         }
      </IonPage>
   )
}

export default PaymentMethods;