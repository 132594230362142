import { ChartSalesResponseDto } from "../../../../dto/home/chart-sales-response"
import { HomeFilterData } from "../../../../dto/home/home-filter-data"
import httpClient from "../../../../service/api"

export class DataStoresBySalesChart {
    dataGridList? : ChartSalesResponseDto[]
    pdvSale?: number[] = []
    directSale?: number[] = []
    store?: string[] = []

    maxSale?: number = 0
}

export const findData = async (filter: HomeFilterData) : Promise<DataStoresBySalesChart> => {
    const response = await httpClient.post('/api/home/chart-sales-by-stores', filter)
    const dataChart : ChartSalesResponseDto[] = response.data
    const data = new DataStoresBySalesChart();
    data.dataGridList = dataChart
    
    data.pdvSale = dataChart.map(item => (item.pdvSale)) as number[]
    data.directSale = dataChart.map(item => (item.directSale)) as number[]
    data.store = dataChart.map(item => (item.store!.id.toString())) as string[]

    const pdvMax = Math.max.apply(null, data.pdvSale)
    const directSaleMax = Math.max.apply(null, data.directSale)
    data.maxSale = Math.max.apply(null, [pdvMax, directSaleMax])

    return data
}
