import { DivH } from "../divh/styled"
import MoneySymbol from "../money-symbol"

interface Param {
   title: string
   value: number
   isShow?: boolean
}

const SaleDetails: React.FC<Param> = ({ title, value, isShow = true }) => {
   return (<>
      {isShow && (
         <DivH style={{ color: '#888' }}>
            <p style={{ fontSize: '0.7em' }}>{title + ' '}</p>
            <p style={{ fontSize: '0.7em' }}><MoneySymbol value={value} /></p>
         </DivH>)
      }
   </>)
}

export default SaleDetails

