// apexcharts-inner apexcharts-graphical

import styled from "styled-components"

export const DivLegend = styled.div`
    overflow: hidden;
    div {
        overflow: hidden;
    }
    .apexcharts-graphical{
        display: none;
    }
    
`
export const DivContainer = styled.div`
    div .scroll {
        overflow-x: auto !important;
        overflow-y: hidden;
        width: 100%;
    } 

    .scroll-container {
        width: -moz-calc(100% - 1px);
        width: -webkit-calc(100% - 1px);
        width: calc(100% - 1px);
    }
`