import { IonAccordion, IonAccordionGroup, IonContent, IonHeader, IonIcon, IonItem } from "@ionic/react";
import { useEffect, useState } from "react";
import Donut from "../../../../../components/donut";
import PercentDetails from "../../../../../components/double-details ";
import Loading from "../../../../../components/loading";
import MoneySymbol from "../../../../../components/money-symbol";
import SaleDetails from "../../../../../components/sale-details";
import { MovProductsResponse } from "../../../../../dto/mov-produtos/mov-produtos-response";
import { MovProductsFilter } from "../../../service";
import { findData, MovProductsModalData } from "./service";

export interface Paran {
    item: MovProductsResponse,
    filter: MovProductsFilter,
    closeModal : Function
}

const ModalProducts: React.FC<Paran> = ({item, filter, closeModal}) => {
    const [data, setData] = useState(new MovProductsModalData())
    const isLoadingData = !data.dataGridList

    useEffect(() => {
        const find = async () => {
            const datachart = await findData({
                ...filter,
                idAggregation: item.id
            })

            setData(datachart)
        }

        if (isLoadingData) {
            find()
        }

    }, [data, isLoadingData, filter, item.id])

    const buildTable = () => {
        return <IonAccordionGroup>
            {
                data.dataGridList!.map((itemProduct: MovProductsResponse, index: number) => {

                    return <IonAccordion key={index} value={index.toString()}>
                        
                        <IonItem slot='header' style={{fontSize: '1em'}}>
                            <div style={{display: 'flex', width: '100%', alignItems: 'center', paddingBottom: 0}}>
                                <Donut valuePercent={itemProduct.totalSalePercentMt} style={{padding: '0.5em 0 0.5em 0'}} />

                                <div>
                                    <p style={{ fontSize: '0.9em', fontWeight: '550'}}>
                                        {itemProduct.label}
                                    </p>

                                    <p style={{fontSize: '0.9em'}}>
                                        <MoneySymbol value={itemProduct.totalSaleValueMt} />
                                    </p>
                                </div>
                            </div>                          
                        </IonItem>

                        <div slot="content" style={{padding: '0 0 12px 24px', marginRight: '43px'}} >
                            <SaleDetails title='Lucro' value={itemProduct.totalProfitValueMt} />
                            <PercentDetails title='Margem ' value={itemProduct.totalMarginMt} />

                            <SaleDetails title='Custo total' value={itemProduct.costValueMt} />
                            <SaleDetails title='Autosserviço' value={itemProduct.pdvSaleValueMt} />
                            <SaleDetails title='DC' value={itemProduct.flexSaleValueMt} />
                            {/* <SaleDetails title='Venda oferta' value={itemProduct.offerValueMt} /> */}
                            <SaleDetails title='Perda' value={itemProduct.lossValueMt} />
                            <SaleDetails title='Devoluções de Venda' value={itemProduct.saleDevValueMt} />
                            {/* <SaleDetails title='Quantidade' value={itemProduct.} /> */}
                            <SaleDetails title='Descontos' value={itemProduct.descountValueMt} />
                            {/* <SaleDetails title='Cancelamentos' value={itemProduct.cancellationValueMt} /> */}
                            <SaleDetails title='Acréscimos' value={itemProduct.additionValueMt} />
                        </div>
                    </IonAccordion>

                })
            }
        </IonAccordionGroup>
    }

    return <>
    
        <IonHeader >
            {/* Botão de fechar o modal. */}
            <div style={{textAlign: 'end', marginTop: '1em', marginRight: '1em', float: 'right'}}>
               <IonIcon icon='close-outline' style={{color: 'red', fontSize: '1.8em'}} onClick={() => closeModal()} />
            </div>
            

            <div style={{textAlign: 'center', marginTop: '1em', marginBottom: '0.5em'}}>
               <div> 
                  <span style={{width: '100%', color: 'var(--color-primary)', fontSize: '1.2em'}}>Lista de Produtos</span>
               </div>
               <div>
                  <span style={{ fontSize: '0.9em', marginTop: '0.5em', fontWeight: '550'}}>
                     {item.label}
                  </span>
               </div>
            </div>   
        </IonHeader>

        <IonContent> 
            {isLoadingData && 
                <Loading />
            }
            {!isLoadingData && 
                buildTable()
            }
        </IonContent>

      </>
}

export default ModalProducts;