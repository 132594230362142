import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonAlert, useIonToast } from "@ionic/react"
import { createOutline, personAddOutline, trashOutline } from "ionicons/icons"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from 'react-router-dom'
import { DivH } from "../../../components/divh/styled"
import Loading from "../../../components/loading"
import { User } from "../../../dto/user"
import { deleteUser, findData } from "./service"

const UsersList: React.FC = () => {
  const [data, setData] = useState(undefined as User[] | undefined)
  const isLoadingData = !data
  const [presentAlert] = useIonAlert()
  const history = useHistory(); //chamado do hook
  const [present] = useIonToast();

  const location = useLocation()

  const find = async () => {
    const data: User[] = await findData()
    setData(data)
  }

  useEffect(() => {
    if (!data) {
      find()
    }
  }, [data])

  useEffect(() => {
    const sta = location?.state as any
    if (sta && sta.refresh) {
      setData(undefined)
    }
  }, [location?.state])

  const deleteConfirm = (user: User) => {
    return presentAlert({
      header: "Excluir usuários",
      message: "<span> Deseja excluir o usuário <b>'" + user.email + "'</b> </span> ?",
      buttons: [
        {
          text: "Confirmar",
          role: "confirm",
          handler: async () => {
            await deleteUser(user)
            present({
              message: "Usuário '" + user.name + "' deletado com sucesso.",
              duration: 1_500,
              position: 'top'
            })
            find()
          }
        },
        {
          text: "Cancelar",
          role: "cancel"
        }
      ]
    })
  }

  const edit = (user: User) => {
    history.push('/user/form', user);
  }

  const buildContent = () => {
    return <>
      {data!.map((user) => {
        return (
          <IonItem key={user.id}>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <DivH style={{ justifyContent: 'flex-start', height: '100%' }}>
                <span>
                  {user.name}
                </span>
                <p style={{ fontSize: '0.7em', marginLeft: '1em', textAlign: 'left' }}>
                  {user.email}

                  {user.isAdmin &&
                    <IonLabel color="tertiary" style={{ fontSize: '0.7em', fontStyle: 'italic' }}>Admin</IonLabel>
                  }
                </p>
                <div style={{ marginLeft: '1em' }}>{" | "}</div>
                {
                  user.organizations?.map((o) => {
                    return <IonLabel color="tertiary" style={{ fontSize: '0.9em', marginLeft: '0.5em' }}>{o.name}</IonLabel>
                  })
                }
              </DivH>

              <IonButtons>
                <IonButton onClick={() => edit(user)}>
                  <IonIcon icon={createOutline} />
                </IonButton>
                <IonButton onClick={() => deleteConfirm(user)}>
                  <IonIcon icon={trashOutline} />
                </IonButton>
              </IonButtons>
            </div>
          </IonItem>
        )
      })}
    </>
  }

  return (
    <IonPage>

      <IonHeader style={{ marginBottom: "1em" }}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonTitle>Usuários</IonTitle>

          <IonButtons slot="end">

            <IonButton onClick={(e) => { find() }}>
              <IonIcon className='text-white' icon='refresh-outline' />
            </IonButton>
            <IonButton onClick={() => { history.push('/user/form', new User()) }}>
              <IonIcon className='text-white' icon={personAddOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        {isLoadingData &&
          <Loading />
        }
        {!isLoadingData &&
          buildContent()
        }

      </IonContent>
    </IonPage>
  )
}

export default UsersList
