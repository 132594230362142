export interface Segment {
  id:number
  name:string
}

export const compareSegmentWith = (o1: Segment, o2: Segment) => {
  if (!o1 || !o2) {
    return o1 === o2;
  }
  return o1.id === o2.id;
}

export const martMinasSegments = () : Segment[] => {
  return [
    {
      id : 2,
      name : 'AutoServiço'
    }, {
      id : 7,
      name : 'TeleVendas'
    }, {
      id : 10,
      name : 'Entrega'
    }
  ]
}

export const domSegments = () : Segment[] => {
  return [
    {
      id : 2,
      name : 'AutoServiço'
    }, {
      id : 7,
      name : 'TeleVendas'
    }
  ]
}

export const listSegments = (id: number) : Segment[] => {
  if (id === 1) {
    return martMinasSegments()
  } else {
    return domSegments()
  }
}