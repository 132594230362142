import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonMenuButton, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useContext } from 'react';
import ContentNoDataFound from '../../components/content-no-data-found';
import Loading from '../../components/loading';
import RefreshData from '../../components/refresh-data';
import '../../theme/styled';
import ChartHourlySales from './cards/chart-hourly-sales';
import ChartSalesByStores from './cards/chart-sales-by-stores';
import CardClientAmount from './cards/client-amount';
import CardLoss from './cards/loss';
import CardMediumTicket from './cards/medium-ticket';
import CardPercentValue from './cards/percent-value';
import CardProfit from './cards/profit';
import CardRefSale from './cards/ref-sale';
import CardVenda from './cards/sale';
import HomeContext from './context';
import Filter from './filter';
import { findData } from './service';
import { Segment } from '../../dto/segment';

const Home: React.FC = () => {

  const { dataHome, setDataHome } = useContext(HomeContext)
  const isLoadingData = !dataHome.periodValuesDay && !dataHome.periodValuesMonth
  const isDataEmpty = !isLoadingData && !dataHome.periodValuesDay!.value.totalSaleValueMt

  const find = async (filter: any) => {
    const result = await findData(filter)
    setDataHome(result)
  }

  const buildContent = () => {
    if (isDataEmpty) {
      return <ContentNoDataFound />
    }

    return <IonGrid>

      {dataHome.filter.segments!.length > 0 && 
        <div style={{width : '100%', textAlign: 'center', marginTop: '0.5em', marginBottom: '0.2em'}}>
          {
            dataHome.filter.segments?.map((s: Segment, i) => (
              <IonText key={s.id}>{(i > 0 ? ', ' : '') + s.name}</IonText>
            ))
          }
        </div>
      }

      {/* Dados sobre vendas geral, pdv e real do dia e do mês. */}
      <IonRow>
        <IonCol size='6'>
          <CardVenda title='Venda total do dia' value={dataHome.periodValuesDay!.value} />
        </IonCol>

        <IonCol size='6'>
          <CardVenda title='Venda total do mês' value={dataHome.periodValuesMonth!.value} />
        </IonCol>
      </IonRow>

      {/* Venda total do período de referência */}
      <IonRow>
        <IonCol size='6'>
          <CardRefSale value={dataHome.periodValuesDay!.ref} />
        </IonCol>

        <IonCol size='6'>
          <CardRefSale value={dataHome.periodValuesMonth!.ref} />
        </IonCol>
      </IonRow>

      {/* Dados de lucratividade e margem do período */}
      <IonRow>
        <IonCol>
          <CardProfit title='Lucratividade (dia)' value={dataHome.periodValuesDay!.value} valueRef={dataHome.periodValuesDay!.ref} />
        </IonCol>

        <IonCol>
          <CardProfit title='Lucratividade (mês)' value={dataHome.periodValuesMonth!.value} valueRef={dataHome.periodValuesMonth!.ref} />
        </IonCol>
      </IonRow>

      {/* Quantidade de clientes. */}
      <IonRow>
        <IonCol>
          <CardClientAmount title='Qtde. Clientes (dia)' value={dataHome.periodValuesDay!.value} valueRef={dataHome.periodValuesDay!.ref} />
        </IonCol>

        <IonCol>
          <CardClientAmount title='Qtde. Clientes (mês)' value={dataHome.periodValuesMonth!.value} valueRef={dataHome.periodValuesMonth!.ref} />
        </IonCol>
      </IonRow>

      {/* Ticket médio */}
      <IonRow>
        <IonCol>
          <CardMediumTicket title='Ticket médio (dia)' value={dataHome.periodValuesDay!.value} valueRef={dataHome.periodValuesDay!.ref} />
        </IonCol>

        <IonCol>
          <CardMediumTicket title='Ticket médio (mês)' value={dataHome.periodValuesMonth!.value} valueRef={dataHome.periodValuesMonth!.ref} />
        </IonCol>
      </IonRow>

      {/* Venda média  */}
      {/* <IonRow>
                <IonCol>
                  <CardVendaMedia title='Venda Média Diária' value={dataHome.periodValuesDay.value} />
                </IonCol>

                <IonCol>
                  <CardVendaMedia title='Venda Média Diária ano anterior' value={dataHome.periodValuesMonth.value} />
                </IonCol>
              </IonRow> */}

      {/* Perda  */}
      <IonRow>
        <IonCol>
          <CardLoss title='Perdas (dia)' value={dataHome.periodValuesDay!.value} />
        </IonCol>

        <IonCol>
          <CardLoss title='Perdas (mês)' value={dataHome.periodValuesMonth!.value} />
        </IonCol>
      </IonRow>

      {/* Gráfico com vendas por faixa de horas. */}
      <IonRow>
        <IonCol>
          <ChartHourlySales />
        </IonCol>
      </IonRow>

      {/* Devoluções de Venda */}
      <IonRow>
        <IonCol>
          <CardPercentValue title='Devoluções de Venda (dia)' value={dataHome.periodValuesDay!.value.saleDevValueMt} percent={dataHome.periodValuesDay!.value.saleDevValuePercentMt} />
        </IonCol>

        <IonCol>
          <CardPercentValue title='Devoluções de Venda (mês)' value={dataHome.periodValuesMonth!.value.saleDevValueMt} percent={dataHome.periodValuesMonth!.value.saleDevValuePercentMt} />
        </IonCol>
      </IonRow>

      {/* Cancelamento e desconto do dia */}
      <IonRow>
        {/* <IonCol>
                  <CardPercentValue title='Cancelamentos (dia)' value={dataHome.periodValuesDay!.value.cancellationValueMt} percent={dataHome.periodValuesDay!.value.cancellationValuePercentMt} />
                </IonCol> */}

        <IonCol>
          <CardPercentValue title='Descontos (dia)' value={dataHome.periodValuesDay!.value.descountValueMt} percent={dataHome.periodValuesDay!.value.descountPercentMt} />
        </IonCol>

        <IonCol>
          <CardPercentValue title='Descontos (mês)' value={dataHome.periodValuesMonth!.value.descountValueMt} percent={dataHome.periodValuesDay!.value.descountPercentMt} />
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol style={{ display: 'block' }}>
          <ChartSalesByStores />
        </IonCol>
      </IonRow>

    </IonGrid>
  }

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButtons >
            <IonTitle style={{ marginLeft: '1em' }}>Vendas</IonTitle>
          </IonButtons>

          <IonButtons slot="end">
            <RefreshData data={dataHome} exectUpdate={find} label='Home' />
            <Filter />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {isLoadingData &&
          <Loading />
        }

        {!isLoadingData &&
        <>          
          {buildContent()}
        </>
        }
      </IonContent>

    </IonPage>
  )
}

export default Home