import { DivH } from "../divh/styled"
import PercentSymbol from "../percent-symbol"

interface Param {
   title: string
   value: number
}

const PercentDetails: React.FC<Param> = ({title, value}) => {
   return (
      <DivH style={{color: '#888'}}>
         <p style={{fontSize: '0.7em'}}>{title}</p>
         <p style={{fontSize: '0.7em'}}><PercentSymbol value={value}/></p>
      </DivH>
   )
}

export default PercentDetails 