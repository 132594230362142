import { ContentNoDataFoundEl } from "./styled";

const ContentNoDataFound: React.FC = () => {

  return(
    <ContentNoDataFoundEl>
      Nenhum registro encontrado!
    </ContentNoDataFoundEl>
  )
  

}

export default ContentNoDataFound;