import { IonAccordion, IonAccordionGroup, IonButtons, IonContent, IonFooter, IonHeader, IonItem, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { ApexOptions } from "apexcharts";
import { useRef, useState } from "react";
import Chart from "../../components/chart";
import ContentNoDataFound from "../../components/content-no-data-found";
import { DivHMaxWidth, DivMaxWidth } from "../../components/div/styled";
import { DivH } from "../../components/divh/styled";
import Donut from "../../components/donut";
import IconButton from "../../components/icon-button";
import Loading from "../../components/loading";
import MoneySymbol from "../../components/money-symbol";
import SaleDetails from "../../components/sale-details";
import { convertSaleLabel, intToStrg } from "../../utils/format-util";
import Filter from "./filter";
import { OperatorsData, OperatorsResponse, findData, getCurrentValue } from "./service";
import RefreshData from "../../components/refresh-data";

export declare type MasterMetric = 'SALE' | 'CANCELLATION' | 'DESCOUNT' | 'COUPON'

const Operators: React.FC = () => {
   const divContainer = useRef(null as any)
   const [data, setData] = useState(new OperatorsData())

   const isLoadingData = !data.dataGridList
   const isDataEmpty = data.dataGridList && data.dataGridList.length === 0

   const find = async (filter: any) => {
      const datachart = await findData(filter)
      setData(datachart)
   }

   const buildContent = () => {
      if (isDataEmpty) {
         return <ContentNoDataFound />
      }

      return <>
         {buildTable()}
      </>
   }

   const buildChart = () => {
      if (isDataEmpty) {
         return <></>
      }
      const options: ApexOptions = {
         chart: {
            toolbar: {
               show: false,
            },
         },
         xaxis: {
            categories: data.categoriesId,
            labels: {
               rotate: -90,
            }
         },
         dataLabels: {
            enabled: false,
         },
         yaxis: {
            min: 0,
            labels: {
               // show: false,
               formatter: convertSaleLabel,
            }
         }
      }

      const series = [
         {
            name: 'Venda Total',
            data: data.serieValues!,
         }
      ]

      return <Chart options={options} series={series} type="bar" height={200} maxWidth={divContainer.current.offsetWidth} widthPxItem={15} />
   }

   const buildTable = () => {
      return <IonAccordionGroup>
         {
            data!.dataGridList!.map((item: OperatorsResponse, index: number) => {
               return (
                  <IonAccordion key={index} value={index.toString()}>

                     <IonItem slot='header' style={{ fontSize: '1em' }}>

                        <DivHMaxWidth style={{ width: '100%', alignItems: 'center', paddingBottom: 0 }}>
                           <Donut valuePercent={item.currentPercentMt} style={{ padding: '0.5em 0 0.5em 0' }} />
                           <div>
                              <p style={{ fontSize: '0.9em' }}>
                                 {`${item.id} ${item.label}`}
                              </p>

                              <p style={{ fontSize: '0.9em' }}>
                                 <span style={{ fontSize: '0.7em' }}>
                                    {data.filter.masterMetric === "SALE" &&
                                       'Venda '
                                    }
                                    {data.filter.masterMetric === "DESCOUNT" &&
                                       'Desconto '
                                    }
                                    {data.filter.masterMetric === "COUPON" &&
                                       'Cupom '
                                    }
                                    {/* {data.filter.masterMetric === "CANCELLATION" &&
                                    'Cancelamentos '
                                 } */}

                                 </span>

                                 {data.filter.masterMetric === "COUPON" &&
                                    intToStrg(getCurrentValue(data, item))
                                 }
                                 {data.filter.masterMetric !== "COUPON" &&
                                    <MoneySymbol value={getCurrentValue(data, item)} />
                                 }

                              </p>

                           </div>
                        </DivHMaxWidth>

                     </IonItem>

                     <div slot="content" style={{ padding: '0 0 12px 24px', marginRight: '43px' }} >
                        <DivMaxWidth>

                           {data.filter.masterMetric !== "SALE" &&
                              <SaleDetails title='Venda ' value={item.totalSaleValueMt} />
                           }
                           {data.filter.masterMetric !== "DESCOUNT" &&
                              <SaleDetails title='Descontos' value={item.descountValueMt} />
                           }
                           {/* {data.filter.masterMetric !== "CANCELLATION" &&
                           <SaleDetails title='Cancelamentos' value={item.cancellationValueMt} />
                        } */}

                           {data.filter.masterMetric !== "COUPON" &&
                              <DivH style={{ color: '#888' }}>
                                 <p style={{ fontSize: '0.7em' }}>{'Cupom '}</p>
                                 <p style={{ fontSize: '0.7em' }}> {intToStrg(item.couponValueMt)}</p>
                              </DivH>
                           }
                           <SaleDetails title='Acréscimos' value={item.additionValueMt} />
                        </DivMaxWidth>
                     </div>
                  </IonAccordion>
               )
            })
         }
      </IonAccordionGroup>
   }

   const buildFooter = () => {

      const buttons = [
         {
            label: 'Cupom',
            masterMetric: 'COUPON',
            icon: 'cart'
         }, {
            label: 'Vendas',
            masterMetric: 'SALE',
            icon: 'cash'
         }, {
            label: 'Descontos',
            masterMetric: 'DESCOUNT',
            icon: 'tag'
         }
      ]

      return <IonFooter>
         <IonToolbar className='background-white'>
            <IonButtons style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', maxWidth: '25em', margin: '0 auto' }}>
               {buttons.map((button, index) => {
                  return <IconButton
                     key={index}
                     style={{ color: data.filter.masterMetric === button.masterMetric ? 'var(--ion-color-primary)' : '' }}
                     title={button.label}
                     icon={button.icon as any}
                     onClick={() => {
                        changeMasterMetric(button.masterMetric as MasterMetric)
                     }} />
               })}
            </IonButtons>
         </IonToolbar>
      </IonFooter>
   }

   const changeMasterMetric = (value: MasterMetric) => {
      setData({
         filter: {
            ...data.filter,
            masterMetric: value
         }
      } as OperatorsData)

   }

   return (
      <IonPage>
         <IonHeader>
            <IonToolbar>
               <IonButtons slot='start'>
                  <IonMenuButton />
               </IonButtons>

               <IonTitle style={{ marginLeft: '1em' }}>Operadores</IonTitle>

               <IonButtons slot='end'>
                  <RefreshData data={data} exectUpdate={find} label='Operators' />
                  <Filter setData={setData} filter={data.filter} />
               </IonButtons>
            </IonToolbar>
         </IonHeader>

         <div>
            {!isLoadingData &&
               buildChart()
            }
         </div>

         <IonContent ref={divContainer}>
            {isLoadingData &&
               <Loading />
            }

            {!isLoadingData &&
               buildContent()
            }
         </IonContent>

         {!isLoadingData &&
            buildFooter()
         }

      </IonPage>
   )
}

export default Operators;