import { IonAccordion, IonAccordionGroup, IonButtons, IonContent, IonFooter, IonHeader, IonItem, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { ApexOptions } from 'apexcharts';
import React, { useRef, useState } from 'react';
import Chart from '../../components/chart';
import ContentNoDataFound from '../../components/content-no-data-found';
import { DivHMaxWidth, DivMaxWidth } from '../../components/div/styled';
import { DivH } from '../../components/divh/styled';
import Donut from '../../components/donut';
import IconButton from '../../components/icon-button';
import Loading from '../../components/loading';
import MoneySymbol from '../../components/money-symbol';
import RefreshData from '../../components/refresh-data';
import SaleDetails from '../../components/sale-details';
import '../../theme/styled';
import { convertHourLabel, convertSaleLabel, doubleToStr } from '../../utils/format-util';
import Filter from './filter';
import { PeriodData, findData } from './service';

const Periods: React.FC = () => {
    const divContainer = useRef(null as any)
    const [data, setData] = useState(new PeriodData())
    //Sub state
    const isLoadingData = !data.dataGridList
    const isDataEmpty = data.dataGridList && data.dataGridList.length === 0

    const find = async (filter: any) => {
        const datachart = await findData(filter)
        setData(datachart)
    }

    const buildContent = () => {
        if (isDataEmpty) {
            return <ContentNoDataFound />
        }

        return <>
            {buildTable()}
        </>
    }

    const buildChart = () => {
        if (isDataEmpty) {
            return <> </>
        }
        const options: ApexOptions = {
            chart: {
                toolbar: {
                    show: false,
                },
            },
            stroke: {
                width: 2,
                curve: 'straight',
            },
            xaxis: {
                categories: data.categorieLabelAggregation,
                labels: {
                    formatter: formatterLabelAggregation,
                    rotate: -90,
                }
            },
            dataLabels: {
                enabled: false,
            },
            yaxis: {
                min: 0,
                max: data.maxValue,
                labels: {
                    show: true,
                    formatter: convertSaleLabel,
                },

            },
            annotations: {
                points:
                    [
                        {
                            x: formatterLabelAggregation(data.maxHour),
                            y: data.maxSale,
                            marker: {
                                size: 2,
                                strokeColor: 'var(--color-max)',
                                fillColor: 'var(--color-max)'
                            },
                            label: {
                                offsetY: -8,
                                text: formatterLabelAggregation(data.maxHour) + ': R$' + convertSaleLabel(data.maxSale),
                                borderWidth: 0,
                                style: {
                                    color: 'var(--color-max)',
                                    fontSize: '0.8em',
                                    background: 'transparent',
                                }
                            }
                        },

                        {
                            x: formatterLabelAggregation(data.minHour),
                            y: data.minSale,
                            marker: {
                                size: 2,
                                strokeColor: 'var(--color-min)',
                                fillColor: 'var(--color-min)'
                            },
                            label: {
                                offsetY: -8,
                                textAnchor: data.minIndex! > data.categorieLabelAggregation.length / 2 ? 'end' : 'start',
                                text: formatterLabelAggregation(data.minHour) + ': R$' + convertSaleLabel(data.minSale),
                                borderWidth: 0,
                                style: {
                                    color: 'var(--color-min)',
                                    fontSize: '0.8em',
                                    background: 'transparent',
                                }
                            }
                        }
                    ]
            }
        }

        const series = [
            {
                name: 'Venda Autosserviço',
                data: data.seriePdvSaleValueMt,
            },
            {
                name: 'DC',
                data: data.serieFlexSaleValueMt,
            },
            {
                name: 'Custo total',
                data: data.seriecostValueMt,
            },
        ]

        return <Chart options={options} series={series} height={200} maxWidth={divContainer.current.offsetWidth} widthPxItem={15} />

    }

    const buildTable = () => {
        return <IonAccordionGroup>
            {
                data.dataGridList!.map((item: any, index: number) => {
                    return (
                        <IonAccordion key={index} value={index.toString()}>
                            <IonItem slot='header' style={{ fontSize: '1em' }}>
                                <DivHMaxWidth style={{ width: '100%', alignItems: 'center', paddingBottom: 0 }}>
                                    <Donut valuePercent={item.totalSaleValuePercentMt} style={{ padding: '0.5em 0 0.5em 0' }} />
                                    <div>
                                        {buildLabelAggregation(item)}

                                        <p style={{ fontSize: '0.9em' }}>
                                            <span style={{ fontSize: '0.6em' }}>{'Venda '}</span>
                                            <MoneySymbol value={item.totalSaleValueMt} />
                                        </p>
                                    </div>
                                </DivHMaxWidth>
                            </IonItem>

                            <div slot="content" style={{ padding: '0 0 12px 24px', marginRight: '43px' }} >
                                <DivMaxWidth>
                                    <SaleDetails title='Lucro' value={item.totalProfitValueMt} />
                                    <DivH style={{ color: '#888' }}>
                                        <p style={{ fontSize: '0.7em' }}>Margem %</p>
                                        <p style={{ fontSize: '0.7em' }}>{doubleToStr(item.totalMarginMt)}</p>
                                    </DivH>
                                    <SaleDetails title='Custo total' value={item.costValueMt} />
                                    <SaleDetails title='Autosserviço' value={item.pdvSaleValueMt} />
                                    <SaleDetails title='DC' value={item.flexSaleValueMt} />
                                    <SaleDetails title='Devoluções de Venda' value={item.saleDevValueMt} />
                                    <SaleDetails title='Ticket Médio' value={item.averageTicketAtr} />
                                    <DivH style={{ color: '#888' }}>
                                        <p style={{ fontSize: '0.7em' }}>Qtde de Clientes</p>
                                        <p style={{ fontSize: '0.7em' }}>{item.amountClientMt}</p>
                                    </DivH>
                                    <SaleDetails title='Descontos' value={item.descountValueMt} />
                                    {/* <SaleDetails title='Cancelamentos' value={item.cancellationValueMt} /> */}
                                    <SaleDetails title='Acréscimos' value={item.additionValueMt} />
                                </DivMaxWidth>
                            </div>
                        </IonAccordion>
                    )
                })
            }
        </IonAccordionGroup>

    }

    const buildFooter = () => {

        const buttonsFooter = [
            {
                label: 'Faixa Hora',
                aggregation: 'HOUR'
            }, {
                label: 'Diário',
                aggregation: 'DAY'
            }, {
                label: 'Mensal',
                aggregation: 'MONTH'
            }, {
                label: 'Anual',
                aggregation: 'YEAR'
            }
        ]

        return <IonFooter>
            <IonToolbar className='background-white' >
                <IonButtons style={{background: 'white', display: 'flex', justifyContent: 'space-around', alignItems: 'center', maxWidth: '25em', margin: '0 auto' }}>
                    {buttonsFooter.map((button, index) => {
                        return <IconButton
                            key={index}
                            style={{ color: data.filter.aggregationType === button.aggregation ? 'var(--ion-color-primary)' : '' }}
                            title={button.label}
                            icon={button.aggregation === 'HOUR' ? 'time' : 'calendar'}
                            onClick={() => { changeAggregation(button.aggregation) }}
                        />
                    })}
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    }

    const formatterLabelAggregation = (label: string) => {
        if (data.filter.aggregationType === 'HOUR') {
            return convertHourLabel(label)
        } else {
            return label + ' '
        }

    }

    const changeAggregation = (value: any) => {
        setData({
            filter: {
                ...data.filter,
                aggregationType: value
            }
        } as PeriodData)
    }

    const buildLabelAggregation = (item: any) => {
        if (data.filter.aggregationType === 'HOUR') {
            return (
                <p style={{ fontSize: '0.9em', fontWeight: '600' }}>
                    {`${item.labelAggregation}:00 às ${item.labelAggregation}:59`}
                </p>
            )
        } else {
            return (
                <p style={{ fontSize: '0.9em', fontWeight: '600' }}>
                    {item.labelAggregation}
                </p>
            )
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot='start'>
                        <IonMenuButton />
                    </IonButtons>

                    <IonTitle style={{ marginLeft: '1em' }}>Períodos</IonTitle>

                    <IonButtons slot='end'>
                        <RefreshData data={data} exectUpdate={find} label='Period' />
                        <Filter setData={setData} filter={data.filter} />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <div>
                {!isLoadingData &&
                    buildChart()
                }
            </div>

            <IonContent ref={divContainer} >
                {isLoadingData &&
                    <Loading />
                }

                {!isLoadingData &&
                    buildContent()
                }
            </IonContent>

            {!isLoadingData &&
                buildFooter()
            }

        </IonPage>
    );
};

export default Periods;