import { MovProductsResponse } from "../../dto/mov-produtos/mov-produtos-response";
import { Store } from "../../dto/store/store";
import httpClient from "../../service/api";
import { defaultDateEndFilter, defaultDateStartFilter } from "../../utils/format-util";

export declare type AggregationType = 'DEPARTMENT' | 'GROUPS' | 'BRAND' | 'BUYERS'

export class MovProductsFilter {
  dateStart: string = defaultDateStartFilter().toISOString()
  dateEnd: string = defaultDateEndFilter().toISOString()
  
  aggregationType: AggregationType = 'DEPARTMENT'
  stores: Store[] = []
  resultLimit?: number
}

export class MovProductsData {
  filter : MovProductsFilter = new MovProductsFilter()
  dataGridList? : MovProductsResponse[]
  date?: Date

  serieTotalSaleValueMt : number [] = []
  serieOfferValueMt : number [] = []
  serieFlexSaleValueMt : number [] = []
  serieGoalValueMt : number [] = []
  serieLossValueMt : number [] = []
  serieCostValueMt : number [] = []
  categorieLabel : string[] = []
}

export const findData = async (filter: MovProductsFilter) : Promise<MovProductsData> => {
  const response = await httpClient.post('/api/product-movement/process-period', filter);
  const dataResponse : MovProductsResponse[]  = response.data

  const data = new MovProductsData()
  data.filter = filter
  data.dataGridList = dataResponse
  data.date = new Date()

  data.serieTotalSaleValueMt = dataResponse.map(item => item.totalSaleValueMt)
  data.serieOfferValueMt = dataResponse.map(item => item.offerValueMt)
  data.serieFlexSaleValueMt = dataResponse.map(item => item.flexSaleValueMt)
  data.serieGoalValueMt = dataResponse.map(item => item.goalValueMt)
  data.serieLossValueMt = dataResponse.map(item => item.lossValueMt)
  data.serieCostValueMt = dataResponse.map(item => item.costValueMt)
  data.categorieLabel = dataResponse.map(item => item.id + "")

  return data;
}