import { IonButton, IonButtons, IonContent, IonDatetime, IonDatetimeButton, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { compareStoreWith, Store } from "../../../dto/store/store";
import { findAllStores } from "../../home/filter/service";
import { ButtonOpenModal } from "../../home/filter/styled";
import { PaymentMethodsFilter } from "../service";

export interface Paran {
   setData: any
   filter: PaymentMethodsFilter
}

const Filter: React.FC<Paran> = ({setData, filter}) => {
   const [open, setOpen] = useState(false);
   const formRef = useRef({} as any)
   const [allStores, setAllStores] = useState([] as Store[])
   
   useEffect(() => {
      if (open) {
        const find = async () => {
          const allStores = await findAllStores()
          setAllStores(allStores)
        }
  
        find()
      }
    }, [open])
   
   const closeModal = () => {
      setOpen(false);
   }

   const submit = async (values: PaymentMethodsFilter) => {
      closeModal()
      setData({
         filter : {
            ...filter, 
            ...values
         } 
      })
   }

   const applyFilter = () => {
      if (formRef.current) {
         formRef.current.handleSubmit()
      }
   }

   return (
      <>
         <ButtonOpenModal onClick={() => setOpen(true)}>
            <IonIcon className='text-white' icon='options-outline' />
         </ButtonOpenModal>

         <IonModal
            isOpen={open}
            onDidDismiss={closeModal}
            breakpoints={[0, 0.2, 0.5, 0.8, 1]}
            initialBreakpoint={0.5}
            backdropBreakpoint={0.2}
         >
            <IonHeader>
               <IonToolbar>
                  <IonButtons slot="start">
                     <IonButton className='text-white' onClick={() => setOpen(false)}>Cancelar</IonButton>
                  </IonButtons>

                  <IonTitle  style={{ textAlign: 'center' }}>Filtro</IonTitle>

                  <IonButtons slot="end">
                     <IonButton className='text-white' strong={true} onClick={() => applyFilter()}
                     //   disabled={!formRef.current.isValid || formRef.current.isSubmitting || !formRef.current.dirty}
                     >Aplicar</IonButton>
                  </IonButtons>
               </IonToolbar>
            </IonHeader>

            <IonContent>

               {/* Formulário de filtro */}
               <Formik
                  innerRef={formRef}
                  initialValues={filter}
                  onSubmit={submit}
                  validationSchema={
                     Yup.object().shape({
                        dateStart: Yup.date().required(),
                        dateEnd: Yup.date().required(),
                        stores: Yup.array(),
                        resultLimit: Yup.number().min(0),
                     })
                  }
               >
                  {(propsForm) => (
                     <Form id='my-form'>
                        <IonList>
                           <IonItem>
                              <IonLabel>Início :</IonLabel>
                              <IonDatetimeButton datetime="datetimeStart" />

                              <IonModal keepContentsMounted={true} >
                                 <IonDatetime 
                                    id="datetimeStart"
                                    name="dateStart"
                                    onIonChange={propsForm.handleChange}
                                    onIonBlur={propsForm.handleBlur}
                                    value={propsForm.values.dateStart}
                                    locale="pt-BR" 
                                    presentation={'date'}
                                    showDefaultButtons={true} 
                                    style={{width: '100%'}}
                                 />
                              </IonModal>
                           </IonItem>

                           <IonItem>
                              <IonLabel>Fim :  </IonLabel>
                              <IonDatetimeButton datetime="datetimeEnd"/>
                        
                              <IonModal keepContentsMounted={true}>
                                 <IonDatetime 
                                    id="datetimeEnd"
                                    name="dateEnd"
                                    onIonChange={propsForm.handleChange}
                                    onIonBlur={propsForm.handleBlur}
                                    value={propsForm.values.dateEnd}
                                    locale="pt-BR" 
                                    presentation={'date'}
                                    showDefaultButtons={true}
                                    style={{width: '100%'}}
                                 />
                              </IonModal>
                           </IonItem>

                           <IonItem style={{ marginRight : '1em'}}>
                              <IonLabel>Unidades</IonLabel>
                              <IonSelect
                                 value={propsForm.values.stores}
                                 name='stores'
                                 placeholder="Todas as lojas"
                                 compareWith={compareStoreWith}
                                 onIonChange={propsForm.handleChange} 
                                 onIonBlur={propsForm.handleBlur} 
                                 multiple={true}
                              >
                                 {allStores.map((store) => (
                                    <IonSelectOption key={store.id} value={store}>
                                    {store.name}
                                    </IonSelectOption>
                                 ))}
                              </IonSelect>
                           </IonItem>

                           <IonToolbar className='filter-white'>
                              <IonButtons slot="end" style={{ marginRight: '2em' }}>
                                 <IonButton onClick={() => { propsForm.setFieldValue('stores', []) }} >
                                    <IonIcon icon='square-outline' />
                                 </IonButton>
                                 <IonButton onClick={() => { propsForm.setFieldValue('stores', allStores) }} >
                                    <IonIcon icon='checkmark-done-outline' />
                                 </IonButton>
                              </IonButtons>
                           </IonToolbar>
                           
                           {/* <IonItem style={{ marginRight : '1em'}}>
                              <IonLabel>Qtn registros : </IonLabel>

                              <IonInput  
                                 value={propsForm.values.resultLimit + ''}
                                 name='resultLimit'
                                 onIonChange={propsForm.handleChange} 
                                 onIonBlur={propsForm.handleBlur} 
                                 type="number"
                                 style={{textAlign : "right"}}
                              /> 
                           </IonItem> */}

                        </IonList>
                     </Form>
                  )}
               </Formik>

            </IonContent>
         </IonModal>
      </>
   );
};

export default Filter;