import { Organization } from "../../../dto/organization"
import { Store } from "../../../dto/store/store"
import httpClient from "../../../service/api"
import { getCurrentOrganization } from "../../../service/session"

// Busca a lista com todas as lojas.
export const findAllStores = async (): Promise<Store[]> => {
    const org: Organization = await getCurrentOrganization()
    const response = await httpClient.get('/api/store?organizationIdAtr=' + org.id)
    return response.data
}

// Busca a lista com todas os compradores.
export const findAllBuyers = async (): Promise<Store[]> => {
    const org: Organization = await getCurrentOrganization()
    const response = await httpClient.get('/api/buyer?organizationIdAtr=' + org.id)
    return response.data
}