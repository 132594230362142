import styled from "styled-components"
import { DivH } from "../divh/styled"

export const DivMaxWidth = styled.div`
  max-width: 18em;
  margin: auto;
`

export const DivHMaxWidth = styled(DivH)`
  max-width: 18em;
  margin: auto;
  text-align: end;
  align-items: start;
  padding-bottom: 0.3em;
`