import React, { createContext, useState } from "react";
import { defaultDateStartFilter } from "../../utils/format-util";
import { HomeData } from "./service";

// Tipando as Props do contexto
type PropsStateContext = {
  dataHome: HomeData
  setDataHome: React.Dispatch<React.SetStateAction<HomeData>>
}

// Valor default do contexto
const DEFAULT_VALUE : PropsStateContext = {
  dataHome: { 
      filter: { 
      date: defaultDateStartFilter().toISOString(),
      stores: [],
      segments: [],
      },
  },

  setDataHome: () => {}, // Função de inicialização.
}

// Criando nosso contexto HomeContext.
const HomeContext = createContext<PropsStateContext>(DEFAULT_VALUE);

// Função que irá conter o estado e função que irá alterar o estado 'setState' quer irá prover o contexto para os componentes filhos da árvore.
const HomeContextProvider: React.FC = ({ children }) => {
  const [dataHome, setDataHome] = useState(DEFAULT_VALUE.dataHome);
  return (
    <HomeContext.Provider
      value={{
        dataHome,
        setDataHome
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export { HomeContextProvider };
export default HomeContext;