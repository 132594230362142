import { IonAccordion, IonAccordionGroup, IonButtons, IonContent, IonHeader, IonItem, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { ApexOptions } from "apexcharts";
import { useRef, useState } from "react";
import Chart from "../../components/chart";
import ContentNoDataFound from "../../components/content-no-data-found";
import { DivHMaxWidth, DivMaxWidth } from "../../components/div/styled";
import Donut from "../../components/donut";
import LabelMoneySymbol from "../../components/label-money-symbol";
import Loading from "../../components/loading";
import RefreshData from "../../components/refresh-data";
import SaleDetails from "../../components/sale-details";
import { PdvResponse } from "../../dto/pdv/pdv-response";
import { convertSaleLabel } from "../../utils/format-util";
import Filter from "./filter";
import { PdvData, findData } from "./service";
import UnitDetails from "../../components/unit-details";
import LabelUnitSymbol from "../../components/label-unit-symbol";

const Pdv: React.FC = () => {
   const divContainer = useRef(null as any)
   const [data, setData] = useState(new PdvData())
   const isLoadingData = !data.dataGridList
   const isDataEmpty = data.dataGridList && data.dataGridList.length === 0

   const find = async (filter: any) => {
      const datachart = await findData(filter)
      setData(datachart)
   }

   const buildContent = () => {
      if (isDataEmpty) {
         return <ContentNoDataFound />
      }

      return <>

         <IonAccordionGroup>
               {
                  data!.dataGridList!.map((item: PdvResponse, index: number) => {
                     return(

                        <IonAccordion key={index} value={index.toString()}>
                           <IonItem slot='header' style={{fontSize: '1em'}}>
                              <DivHMaxWidth style={{ width: '100%', alignItems: 'center', paddingBottom: 0}}>

                                 <Donut valuePercent={item.percentMt} style={{padding: '0.5em 0 0.5em 0'}} />
                                 <div>
                                    <p style={{ fontSize: '0.9em', fontWeight: '600'}}>
                                       {'pdv : ' + item.id}
                                    </p>
                                    
                                    <p style={{fontSize: '0.9em'}}>
                                       {(data.filter.orderBy === 'BIPS' || data.filter.orderBy === 'COUPON') &&
                                          <LabelUnitSymbol label={data.orderByValue.label} value={data.orderByValue.getValue(item)} isShow={true} />
                                       }
                                       {(data.filter.orderBy !== 'BIPS' && data.filter.orderBy !== 'COUPON') &&
                                          <LabelMoneySymbol label={data.orderByValue.label} value={data.orderByValue.getValue(item)} isShow={true} />
                                       }
                                    </p>
                                 </div>
                              </DivHMaxWidth>
                           </IonItem>

                           <div slot="content" style={{padding: '0 0 12px 24px', marginRight: '43px'}} >
                              <DivMaxWidth>
                                 <SaleDetails title='Venda' value={item.totalSaleValueMt} isShow={data.filter.orderBy !== 'SALE'}/>
                                 <SaleDetails title='Descontos' value={item.descountValueMt} isShow={data.filter.orderBy !== 'DESCOUNT'}/>
                                 <SaleDetails title='Cancelamentos' value={item.cancellationValueMt} isShow={data.filter.orderBy !== 'CANCELLATION'}/>
                                 <SaleDetails title='Acréscimos' value={item.additionValueMt} isShow={data.filter.orderBy !== 'ADDITION'} />
                                 <UnitDetails title='Cupom' value={item.couponAmountMt} isShow={data.filter.orderBy !== 'COUPON'}/>
                                 <UnitDetails title='Qtn Bips' value={item.bipsAmountMt} isShow={data.filter.orderBy !== 'BIPS'}/>
                              </DivMaxWidth>
                           </div>
                        </IonAccordion>
                     )
                  })
               }
            </IonAccordionGroup>
      </>
   }

   const buildChart = () => {
      if (isDataEmpty) {
         return <></>
      }
      
      const options: ApexOptions = {
         chart: {
           toolbar: {
             show: false, 
           },
         },
         xaxis: {
           categories: data.categorieId,
           labels: {
             rotate: -90,
           }
         },
         dataLabels: {
           enabled: false,
         },
         yaxis:{
           labels: {
             // show: false,
             formatter : convertSaleLabel,
           }
         },
      }
   
      const series = [
         {
            name: data.orderByValue.label,
            data: data.serieValues
         }
      ]

      return <Chart options={options} series={series} height={200} type="bar" maxWidth={divContainer.current.offsetWidth} widthPxItem={15}/>
   }

   return(
      <IonPage>
         <IonHeader>
            <IonToolbar>
               <IonButtons slot='start'>
                  <IonMenuButton />
               </IonButtons>

               <IonTitle style={{ marginLeft: '1em' }}>PDV</IonTitle>

               <IonButtons slot='end'>
                  <RefreshData data={data} exectUpdate={find} label='Pdv' />
                  <Filter setData={setData} filter={data.filter} allStoreP={data.allStores}/>
               </IonButtons>

            </IonToolbar>
         </IonHeader>

         <div>
            {!isLoadingData && 
            <>
               {data.filter.store &&
                  <div style={{textAlign: 'center'}}>
                     <h3>{data.filter.store.id + ' ' + data.filter.store.name}</h3> 
                  </div>
               }
               {buildChart()}
            </>
            }
         </div>

         <IonContent ref={divContainer}>
            {isLoadingData && 
               <Loading /> 
            }

            {!isLoadingData && 
               buildContent()
            }
         </IonContent>
      </IonPage>
   )
}

export default Pdv;