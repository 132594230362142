import { IonCard } from "@ionic/react";
import styled from "styled-components";

interface Handle{
    isActive?: boolean;
}

export const CardEl = styled(IonCard)<Handle>`
    margin: 0.3em;
    height: ${({ isActive }) => (isActive ? "100%" : "")};
`;