import { IonButton, IonIcon, IonText, useIonToast, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { dateTimeToStrg } from '../../utils/format-util'
import { getCurrentOrganization } from '../../service/session'
import { Organization } from '../../dto/organization'

interface Param {
  data: any
  exectUpdate?: any
  label: String
  timerMinute:number
}

const RefreshData: React.ElementType<Param> = ({ exectUpdate, label, data, timerMinute = 5}) => {
  const [valid, setValid] = useState(true)
  const [currentInterval, setCurrentInterval] = useState(undefined as any)

  const [toast] = useIonToast()
  const TIMER_SECOND = timerMinute * 1000 * 60

  if (data.date) {
    const difSecond = Date.now() - data.date!.getTime()
    if (difSecond > TIMER_SECOND && valid) {
      setValid(false)
    }
  }

  useEffect(() => {
    if (!data.date) {
      exec()
    } else {
      clearIntervalL()
      startLoop()
    }
  }, [data])

  useIonViewDidLeave(() => {
    clearIntervalL()
  }, [currentInterval])

  useIonViewDidEnter(() => {
    if (data.date) {
      clearIntervalL()
      exec()
      startLoop()
    }
  }, [data])

  const clearIntervalL = () => {
    if (currentInterval) { 
      clearInterval(currentInterval)
    }
    setCurrentInterval(undefined)
  }

  const startLoop = () => {
    const interval = setInterval(exec, TIMER_SECOND)
    setCurrentInterval(interval)
  }

  const exec = async () => {
    try {
      const org: Organization = await getCurrentOrganization()
      await exectUpdate({...data.filter, organizationIdAtr: org.id})
      setValid(true)
    } catch (e) {
      setValid(false)
    }
  }

  const click = async () => {
    try {
      await exectUpdate(data.filter)
      toast({
        message: "Dados atualizados com sucesso.",
        duration: 2_500,
        position: 'top'
      })
    } catch (e) {
      console.log(e)
      toast({
        message: "Erro ao atualizar dados.",
        duration: 2_500,
        position: 'top',
        color: 'danger'
      })
    }
  }

  return <>
    {valid &&
      <IonButton onClick={click}>
        <IonIcon className='text-white'icon='refresh-outline' />
      </IonButton>
    }

    {!valid &&
      <>
        <IonText style={{ fontSize: '0.8em', color: 'red' }} >{data.date ? dateTimeToStrg(data.date as Date) : 'Desatualizado'}</IonText>

        <IonButton onClick={click} color="danger">
          <IonIcon className='text-white' icon='refresh-outline' />
        </IonButton>
      </>
    }
  </>
}

export default RefreshData