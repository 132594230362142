import { IonAccordionGroup, IonButtons, IonContent, IonFooter, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { ApexOptions } from 'apexcharts';
import React, { useRef, useState } from 'react';
import Chart from '../../components/chart';
import ContentNoDataFound from '../../components/content-no-data-found';
import IconButton from '../../components/icon-button';
import Loading from '../../components/loading';
import RefreshData from '../../components/refresh-data';
import { MovProductsResponse } from '../../dto/mov-produtos/mov-produtos-response';
import '../../theme/styled';
import { convertSaleLabel } from '../../utils/format-util';
import Filter from './filter';
import './index.css';
import Line from './line';
import { MovProductsData, findData } from './service';

const MovProducts: React.FC = () => {
    const divContainer = useRef(null as any)
    const [data, setData] = useState(new MovProductsData())

    const isLoadingData = !data.dataGridList
    const isDataEmpty = !isLoadingData && data.serieTotalSaleValueMt && data.dataGridList!.length === 0

    const find = async (filter: any) => {
        const datachart = await findData(filter)
        setData(datachart)
    }

    const buildContent = () => {
        if (isDataEmpty) {
          return <ContentNoDataFound />
        }

        return <>
            {buildTable()}
        </>
    }

    const buildChart = () => {
        if (isDataEmpty) {
            return <></>
          }
        const options: ApexOptions = {
            chart: {
                toolbar: {
                    show: false, 
                },
            },
            stroke: {
                width: 2,
                curve: 'straight',
            },
            xaxis: {
                categories: data.categorieLabel,
                labels: {
                  rotate: -90,
                }
            },
            dataLabels: {
                enabled: false,
            },
            yaxis: {
                min:0,
                labels: {
                    show: true,
                    formatter: convertSaleLabel,
                }
            },
        }
    
        const series = [
            {
                name: 'Venda Normal',
                data: data.serieTotalSaleValueMt,
                type: 'bar',
            },
            {
                name: 'Ofertas',
                data: data.serieOfferValueMt,
                type: 'bar',
            },
            {
                name: 'DC',
                data: data.serieOfferValueMt,
                type: 'bar',
            },
            // {
            //     name: 'Meta',
            //     data: data.serieGoalValueMt,
            //     type: 'line',
            // },
            // {
            //     name: 'Perdas',
            //     data: data.serieLossValueMt,
            //     type: 'line',
            // },
            // {
            //     name: 'Custo total',
            //     data: data.serieCostValueMt,
            //     type: 'line',
            // },
        ]

        return <div >
            <Chart options={options} series={series} height={200} maxWidth={divContainer.current.offsetWidth} widthPxItem={15}/>
        </div>
    }

    const buildTable = () => {
        return  <IonAccordionGroup>
            {
                data.dataGridList!.map((item: MovProductsResponse, index: number) => {
                    return <Line filter={data.filter} item={item} key={index}/>
                })
            }
        </IonAccordionGroup>
    }

    const buildFooter = () => {

        const buttons = [
            {
                label : 'Depart',
                aggregation : 'DEPARTMENT',
                icon: 'business'
            }, {
                label : 'Grupos',
                aggregation : 'GROUPS',
                icon: 'grid'
            }, {
                label : 'Marcas',
                aggregation : 'BRAND',
                icon: 'star'
            }, {
                label : 'Comprad',
                aggregation : 'BUYERS',
                icon: 'people'
            }
        ]

        return <IonFooter>
            <IonToolbar className='background-white'>
                <IonButtons style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', maxWidth: '25em', margin: '0 auto'}}>
                    {buttons.map((button, index) => {
                        return <IconButton
                            key={index}
                            style={{color : data.filter.aggregationType === button.aggregation ? 'var(--ion-color-primary)' : ''}} 
                            title={button.label}
                            icon={button.icon as any}
                            onClick={() => { changeAggregation(button.aggregation) }} 
                        />
                    })}
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    }

    const changeAggregation = (value: any) => {
        setData({
            filter : {
               ...data.filter, 
               aggregationType: value
            }
         } as MovProductsData)
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot='start'>
                        <IonMenuButton />
                    </IonButtons>

                    <IonTitle style={{ marginLeft: '1em' }}>Mov. Produtos</IonTitle>

                    <IonButtons slot='end'>
                        <RefreshData data={data} exectUpdate={find} label='Product' />
                        <Filter setData={setData} filter={data.filter} />
                    </IonButtons>

                </IonToolbar>
            </IonHeader>

            <div>
                {!isLoadingData && 
                    buildChart()
                }
            </div>

            <IonContent ref={divContainer}>
                {isLoadingData && 
                    <Loading /> 
                }

                {!isLoadingData && 
                    buildContent()
                }
            </IonContent>

            {!isLoadingData && 
                buildFooter()
            }

        </IonPage>
    );
};

export default MovProducts;