import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import Loading from './components/loading';
import Menu from './components/menu/Menu';
import Home from './pages/home';
import { HomeContextProvider } from './pages/home/context';
import Login from './pages/login';
import MovProducts from './pages/mov-products';
import Operators from './pages/operators';
import PaymentMethods from './pages/payment-methods';
import Pdv from './pages/pdv';

import Periods from './pages/periods';
import Stores from './pages/stores';
import UsersForm from './pages/user/form';
import UsersList from './pages/user/list';
import { getCurrentOrganization, initSession, logout as logoutSession } from './service/session';
import { StyledContent } from './theme/styled';
/* Theme variables */
import './theme/variables.css';
import Goal from './pages/goal';
import { Organization } from './dto/organization';

setupIonicReact();

export enum ApplicationStatus {
  INITIALIZING,
  NO_USER,
  LOGGED_IN_USER,
}

const App: React.FC = () => {
  const [applicationStatus, setApplicationStatus] = useState(ApplicationStatus.INITIALIZING)

  console.log('App', ApplicationStatus[applicationStatus])

  useEffect(() => {
    if (applicationStatus === ApplicationStatus.INITIALIZING) {
      init()
    }
  }, [applicationStatus])

  const init = async () => {
    const session = await initSession()

    if (session) {
      setApplicationStatus(ApplicationStatus.LOGGED_IN_USER)

      const org: Organization = await getCurrentOrganization()
      const htmlEl:any = document.querySelector('html');
      //Cor principal
      let color

      if (org.id === 1) {
        //MartMinas
        color = '#00A651'
        htmlEl.style.setProperty('--ion-color-primary-rgb', '0, 166, 81');
      } else {
        //DOM
        color = '#052846'
        htmlEl.style.setProperty('--ion-color-primary-rgb', '5, 40, 70');
      }
    
      htmlEl.style.setProperty('--ion-color-primary', color);
      htmlEl.style.setProperty('--ion-toolbar-background', color);
      htmlEl.style.setProperty('--color-combo-organization', color);

    } else {
      setApplicationStatus(ApplicationStatus.NO_USER)
    }
  }

  const loginSuccess = () => {
    setApplicationStatus(ApplicationStatus.LOGGED_IN_USER)
  }

  const logout = () => {
    logoutSession()
    setApplicationStatus(ApplicationStatus.NO_USER)
  }

  const buildRouter = () => {
    return (
      <IonReactRouter>
        <IonSplitPane contentId='main'>
          <Menu logout={logout} />
          <IonRouterOutlet id='main' >

            {/* <Route path='/' exact={true}>
              <Redirect to='/page/home' />
              <Home />
            </Route> */}

            <Route path='/' exact={true}>
              <HomeContextProvider>
                <Home />
              </HomeContextProvider>
            </Route>

            <Route path='/page/unidades' exact={true}>
              <Stores />
            </Route>

            <Route path='/page/periodos' exact={true}>
              <Periods />
            </Route>

            <Route path='/page/mov-produtos' exact={true}>
              <MovProducts />
            </Route>

            <Route path='/page/formas-pagamentos' exact={true}>
              <PaymentMethods />
            </Route>

            <Route path='/page/operadores' exact={true}>
              <Operators />
            </Route>

            <Route path='/page/pdv' exact={true}>
              <Pdv />
            </Route>
            
            <Route path='/page/meta' exact={true}>
              <Goal />
            </Route>

            <Route path='/user/list' exact={true}>
              <UsersList />
            </Route>
            
            <Route path='/user/form' exact={true}>
              <UsersForm />
            </Route>

          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    )
  }

  return (
    <IonApp>
      {applicationStatus === ApplicationStatus.INITIALIZING && (
         <Loading /> 
      )}
      {applicationStatus !== ApplicationStatus.INITIALIZING && (
        <StyledContent>
          {applicationStatus === ApplicationStatus.NO_USER &&
            <Login loginSuccess={loginSuccess} />
          }

          {applicationStatus === ApplicationStatus.LOGGED_IN_USER &&
            buildRouter()
          }
        </StyledContent>
      )}
    </IonApp>
  )
}

export default App;
