import { AxiosError } from 'axios'
import { Session } from '../dto/session'
import httpClient from './api'

import { GetResult, Preferences as preferences } from '@capacitor/preferences';
import { Organization } from '../dto/organization';

export const initSession = async (): Promise<Session | undefined> => {
    try {
        const response: any = await httpClient.get('/api/session')
        const session: Session = response.data;
        await preferences.set({ key: 'session', value: JSON.stringify(session) })
        return response.data as Session
    } catch (error: any) {
        if (AxiosError.ERR_NETWORK !== error.code) {
            await logout()
        } else {
            console.error('Erro ao buscar session!', error)
            return getSession()
        }
    }
}

export const login = async (email: string, password: string) => {
    const response = await httpClient.post('/api/auth/login', { email: email, password: password })
    await preferences.set({ key: 'token', value: response.data.token })
    await preferences.set({ key: 'tokenUpdate', value: response.data.tokenUpdate })

    const session: Session = response.data.session;
    await preferences.set({ key: 'session', value: JSON.stringify(session) })
    await preferences.set({ key: 'currentOrganization', value: JSON.stringify(session.organizations[0]) })
}

export const updateToken = async (token: string | null) => {
    if (token) {
        await preferences.set({ key: 'token', value: token })
    } else {
        await preferences.remove({ key: 'token' });
    }
}

export const logout = async () => {
    await preferences.clear()
}

export const getSession = async (): Promise<Session> => {
    const { value } = await preferences.get({ key: 'session' });
    return JSON.parse(value as string) as Session;
}

export const getCurrentOrganization = async (): Promise<Organization> => {
    const result:GetResult = await preferences.get({ key: 'currentOrganization' })
    try {
        return JSON.parse(result.value as string) as Organization;
    } catch (error) {
        // console.error('Erro ao buscar Organization Default', error)
    }
       
    return {} as Organization
}

export const setCurrentOrganization = async (organization: Organization): Promise<void> => {
    await preferences.set({ key: 'currentOrganization', value: JSON.stringify(organization) })
}

export const getToken = async (): Promise<string> => {
    const { value } = await preferences.get({ key: 'token' });
    return value as string
}

export const getTokenUpdate = async (): Promise<string> => {
    const { value } = await preferences.get({ key: 'tokenUpdate' });
    return value as string
}


