import DownArrow from '../down-arrow';
import PercentSymbol from '../percent-symbol';
import UpArrow from '../up-arrow';

interface Param {
    percent?: number;
    limitGood?: number;
    limitBad?: number;
    label?: string
}

const IndicatorPercentValue: React.FC<Param> = ({percent = 0, limitGood = 20, limitBad = -20, label}) => {

    let colorValue = "";
    let countIcons;
    
    if (percent >= limitGood) {
        colorValue = "var(--color-very-good)";
        countIcons = 3;
    } else if (percent > 0) {
        colorValue = "var(--color-good)";
        countIcons = 2;
    } else if (percent <= 0 && percent > limitBad) {
        colorValue = "var(--color-bad)";
        countIcons = 2;
    } else {
        colorValue = "var(--color-very-bad)";
        countIcons = 3;
    }
    
    const iconArrow = percent > 0 ? <UpArrow countIcons={countIcons} /> : <DownArrow countIcons={countIcons} />
    
    return (
        <div>
            <div style={{color: colorValue, fontSize: "0.8em", display: "flex", alignItems: "center", gap: "3px", justifyContent: 'center'}}>
                <span>
                    {iconArrow}
                </span>
                <br />
                <span>
                    {<PercentSymbol value={percent} />}
                </span>
            </div>
            {label &&
                <div style={{color: colorValue, fontSize: '0.6em'}}>
                    {label}
                </div>
            }
        </div>
    );
}

export default IndicatorPercentValue;