import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import { cartOutline, cartSharp, cashOutline, cashSharp, contract, fileTrayFullOutline, fileTrayFullSharp, homeOutline, homeSharp, logOut, peopleOutline, personOutline, personSharp, storefrontOutline, storefrontSharp, timeOutline, timeSharp } from 'ionicons/icons';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Session } from '../../dto/session';
import { getCurrentOrganization, getSession, setCurrentOrganization } from '../../service/session';
import './Menu.css';
import { Organization, compareOrganizationWith } from '../../dto/organization';
import logoMM from '../../utils/img/logo-martminas.png'
import logoDom from '../../utils/img/logo-dom.png'

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  onClick?: MouseEventHandler;
}

interface Param {
  logout: Function
}

const Menu: React.FC<Param> = ({ logout }) => {
  const location = useLocation()
  const [sessionData, setSessionData] = useState({} as Session)
  const [organization, setOrganization] = useState({} as Organization)

  const appPages: AppPage[] = [
    {
      title: 'Home',
      url: '/',
      iosIcon: homeOutline,
      mdIcon: homeSharp
    },
    {
      title: 'Unidades',
      url: '/page/unidades',
      iosIcon: storefrontOutline,
      mdIcon: storefrontSharp
    },
    {
      title: 'Períodos',
      url: '/page/periodos',
      iosIcon: timeOutline,
      mdIcon: timeSharp
    },
    {
      title: 'Mov. Produtos',
      url: '/page/mov-produtos',
      iosIcon: cartOutline,
      mdIcon: cartSharp
    },
    {
      title: 'Formas Pagamento',
      url: '/page/formas-pagamentos',
      iosIcon: cashOutline,
      mdIcon: cashSharp
    },
    {
      title: 'Operadores',
      url: '/page/operadores',
      iosIcon: personOutline,
      mdIcon: personSharp
    },
    {
      title: 'PDV',
      url: '/page/pdv',
      iosIcon: fileTrayFullOutline,
      mdIcon: fileTrayFullSharp
    },
    {
      title: 'Metas',
      url: '/page/meta',
      iosIcon: contract,
      mdIcon: contract
    }

  ]

  if (sessionData.isAdmin) {
    appPages.push({
      title: 'Usuários',
      url: '/user/list',
      iosIcon: peopleOutline,
      mdIcon: personSharp
    })
  }

  appPages.push({
    title: 'Sair',
    url: '/',
    iosIcon: logOut,
    mdIcon: logOut,
    onClick: () => {
      logout()
    }
  })

  useEffect(() => {
    const init = async () => {
      const sessionData: Session = await getSession()
      setSessionData(sessionData)

      const org: Organization = await getCurrentOrganization()
      setOrganization(org)
    }
    init()
  }, [])
  
  let logo
  if (organization) {
    const htmlEl:any = document.querySelector('html');
    if (organization.id === 1) {
      htmlEl.style.setProperty('--ion-color-primary-rgb', '0, 166, 81');
      logo = <img style={{height: '4em', margin: 'auto'}} className='logo-menu' src={logoMM} alt='' />
    } else {
      htmlEl.style.setProperty('--ion-color-primary-rgb', '5, 40, 70');
      logo = <img style={{height: '2em', margin: 'auto'}} className='logo-menu' src={logoDom} alt='' />
    }
  } else {
    return <></>
  }

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '100%' }}>
              <IonListHeader className='teste'>Sales Fast Analytics</IonListHeader>
            </div>
          </div>

          <IonNote>{sessionData.email}</IonNote>
          {sessionData && sessionData.organizations && organization && organization.id &&
            <IonSelect value={organization}
              compareWith={compareOrganizationWith}
              onIonChange={async (e) => { await setCurrentOrganization(e.detail.value); window.location.reload() }}
              style={{ width: '7em', marginBottom: '1em', marginTop: '1em', 
              marginLeft: 'auto', color: 'var(--color-combo-organization)', textAlign: 'right' }}>

              {sessionData.organizations ? sessionData.organizations.map((store) => (
                <IonSelectOption key={store.id} value={store}>
                  {store.name}
                </IonSelectOption>
              )) : <></>}
            </IonSelect>
          }


          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem onClick={appPage.onClick} className={location.pathname === appPage.url && index !== (appPages.length - 1) ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel style={{ marginLeft: '1em' }}>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          
        <div style={{width: '100%', textAlign: 'center'}}>
          {logo}
        </div>
        </IonList>

      </IonContent>
    </IonMenu>
  );
};
export default Menu;
