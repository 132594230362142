import { IonAccordion, IonAccordionGroup, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonItem, IonMenuButton, IonPage, IonRange, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { ApexOptions } from 'apexcharts';
import React, { useEffect, useRef, useState } from 'react';
import Card from '../../components/card';
import ContentNoDataFound from '../../components/content-no-data-found';
import { DivMaxWidth } from '../../components/div/styled';
import { DivH } from '../../components/divh/styled';
import PercentDetails from '../../components/double-details ';
import IconButton from '../../components/icon-button';
import IndicatorPercentValue from '../../components/indicator-percent-value';
import Loading from '../../components/loading';
import MoneySymbol from '../../components/money-symbol';
import PercentSymbol from '../../components/percent-symbol';
import RefreshData from '../../components/refresh-data';
import SaleDetails from '../../components/sale-details';
import { GoalResponse } from '../../dto/goal/store-response';
import '../../theme/styled';
import { convertDoubleLabel, convertIntLabel, convertSaleLabel } from '../../utils/format-util';
import Filter from './filter';
import { ChartContext, GoalData, findData, } from './service';
import { H2LabelStoreName, LabelHeader, ValueHeader } from './styled';

import './index.css';

const Goal: React.FC = () => {
  const divRef = useRef({} as any)

  const divContainer = useRef(null as any)
  const [data, setData] = useState(new GoalData())
  const [chartContex, setChartContex] = useState(undefined as undefined | ChartContext)

  //Sub state
  const isLoadingData = !data.dataResponse
  const isDataEmpty = data.dataResponse && data.dataResponse.dataGridList.length === 0

  useEffect(() => {
    buildChart()
  }, [data])

  const find = async (filter: any) => {
    const dataR: GoalData = await findData(filter)

    setData(dataR)
  }

  const buildContent = () => {
    if (isDataEmpty) {
      return <ContentNoDataFound />
    }

    return <>
      {buildTable()}
    </>
  }

  const changeAggregation = async (value: any) => {
    if (value !== data.filter.aggregationType) {
      setData({
        filter: {
          ...data.filter,
          aggregationType: value
        }
      } as GoalData)
    }
  }

  const buildChart = () => {

    if (isLoadingData || isDataEmpty || chartContex) {
      return
    }

    const buildOptions: ApexOptions = {
      legend: {
        show: true
      },
      tooltip :{
        enabled: false
      },
      chart: {
        animations: {
          enabled: false
        },
        height: "200px",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [4, 4, 4],
        curve: 'straight',
      },
      xaxis: {
        max: 8,
        min: 1,
        categories: data.categories,
        labels: {
          rotate: -90,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: [
        {
          max: data.maxSale,
          min: 0,
          labels: {
            show: true,
            formatter: convertSaleLabel,
          }
        },
        {
          show: false,
          max: data.maxSale,
          min: 0,
          labels: {
            show: true,
            formatter: convertSaleLabel,
          }
        },
        {
          max: data.maxAtingimento,
          min: 0,
          opposite: true,
          labels: {
            show: true,
            formatter: convertIntLabel,
          }
        },
      ],
      series: [
        {
          name: 'Meta',
          type: 'bar',
          data: data.serieSalesGoal,
        },
        {
          name: 'Venda',
          type: 'bar',
          data: data.serieSales,
        },
        {
          name: 'Atingimento venda',
          type: 'line',
          data: data.serieAchievement,
        },
      ]
    }

    var chart: ApexCharts = new ApexCharts(document.querySelector("#chart"), buildOptions);
    chart.render()

    const storesLength = data.dataResponse!.dataGridList.length
    console.log('Quantidade de itens ', storesLength)

    const windowWidth = divRef.current.offsetWidth
    console.log("windowWidth", windowWidth)

    let widthItem = windowWidth / storesLength
    console.log("widthItem", widthItem)

    const limitMinWidthItem = 20
    console.log("limit por tela ", limitMinWidthItem)

    let percentScroll
    const itensPorTela = Math.round(windowWidth / limitMinWidthItem)
    console.log('itens por tela ', itensPorTela)

    if (widthItem < limitMinWidthItem) {
      widthItem = limitMinWidthItem
      percentScroll = Math.round((storesLength * 100) / itensPorTela)
      chart.zoomX(1, itensPorTela)
    }
    
    console.log("percentScroll", percentScroll)

    const chartContexx: ChartContext = {
      chart: chart,
      widthPxItem: widthItem,
      itensPorTela: itensPorTela,
      divScrollWidthPercent: percentScroll,
      ionRangeMax: storesLength - itensPorTela
    }

    setChartContex(chartContexx)
  }

  //Atualiza o gráfico de acordo com o scroll da div
  const onScrollH = (e: any) => {
    const scrollLeft: number = (e.target as any).scrollLeft as number

    let startIndex = Math.round(scrollLeft / chartContex!.widthPxItem)

    if (startIndex < 1) {
      startIndex = 1
    }

    let lastChange = e.target.lastChange

    if (!lastChange) {
      lastChange = 0
    }

    const dif = startIndex - lastChange
    if (dif >= 1 || dif <= -1) {
      e.target.lastChange = startIndex
      const lastIndex = startIndex + chartContex?.itensPorTela!
      chartContex!.chart!.zoomX(startIndex, lastIndex)
    }
  }

  const buildTable = () => {
    return <IonAccordionGroup  >
      {
        data.dataResponse!.dataGridList!.map((item: GoalResponse) => {
          return (

            <IonAccordion className={'class-' + item.id} id='accordionId' key={item.id?.toString()} value={item.id?.toString()}>

              {/* Header */}
              <IonItem slot="header" style={{ fontSize: '1em', textAlign: 'center' }}>

                <DivMaxWidth style={{ width: '100%' }}>

                  <H2LabelStoreName>
                    {`${item.label} `}
                  </H2LabelStoreName>

                  <DivH style={{ textAlign: 'left', alignItems: 'start', paddingBottom: '0.5em' }}>
                    <div>
                      <LabelHeader>Meta venda</LabelHeader>

                      <ValueHeader>
                        <MoneySymbol value={item.salesGoalValueMt} />
                      </ValueHeader>

                    </div>

                    <div>
                      <LabelHeader>Tendência venda</LabelHeader>
                      <ValueHeader>
                        <MoneySymbol value={item.salesTrendValueMt} />
                      </ValueHeader>
                    </div>
                  </DivH>

                </DivMaxWidth>
              </IonItem>

              <div slot="content" style={{ padding: '0 0 12px 24px', marginRight: '43px' }}>

                <DivMaxWidth>
                  <SaleDetails title='Meta venda' value={item.salesGoalValueMt} />
                  <SaleDetails title='Venda' value={item.totalSaleValueMt} />
                  <SaleDetails title='Tendência' value={item.salesTrendValueMt} />
                  <PercentDetails title='Atingimento venda' value={item.salesAchievementPercentMt} />
                  <PercentDetails title='Meta margem' value={item.totalMarginGoalPercentMt} />
                  <PercentDetails title='Margem' value={item.totalMarginPercentMt} />
                  <PercentDetails title='Mark Down' value={item.markDownPercentMt} />
                </DivMaxWidth>
              </div>
            </IonAccordion>
          )
        })
      }
    </IonAccordionGroup>
  }

  const buildFooter = () => {

    const buttonsFooter = [
      {
        label: 'Lojas',
        aggregation: 'STORES',
        icon: 'storefront'
      }, {
        label: 'Comprador',
        aggregation: 'BUYERS',
        icon: 'people'
      }
    ]

    return <IonFooter>
      <IonToolbar className='background-white'>
        <IonButtons style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', maxWidth: '25em', margin: '0 auto' }}>
          {buttonsFooter.map((button, index) => {
            return <IconButton
              key={index}
              style={{ color: data.filter.aggregationType === button.aggregation ? 'var(--ion-color-primary)' : '' }}
              title={button.label}
              icon={button.icon}
              onClick={() => { changeAggregation(button.aggregation) }}
            />
          })}
        </IonButtons>
      </IonToolbar>
    </IonFooter>
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonTitle style={{ marginLeft: '1em' }}>Metas</IonTitle>

          <IonButtons slot="end">
            <RefreshData data={data} date={data.date} exectUpdate={find} label='Goal' timerMinute={60} />
            <Filter setData={setData} filter={data.filter} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div >
        {!isLoadingData && !isDataEmpty &&
          <>
            <IonGrid>
              <IonRow>
                <IonCol size='6' sizeXs='7'>
                  <Card>
                    <h3 style={{ textAlign: 'center' }}>Venda</h3>
                    <DivH style={{ justifyContent: 'space-around' }}>
                      <div className='goal-h2-left' style={{ textAlign: 'center', width: '50%' }}>
                        <h4>Meta </h4>
                        <h3><MoneySymbol value={data.dataResponse!.salesGoalValueMt} /></h3>
                      </div>
                      <div className='goal-h2-right' style={{ textAlign: 'center', width: '50%' }}>
                        <h4>Realizado </h4>
                        <h3><MoneySymbol value={data.dataResponse!.totalSaleValueMt} /></h3>
                      </div>
                    </DivH>

                    <DivH style={{ justifyContent: 'space-around' }}>
                      <div style={{ textAlign: 'center', fontSize: '0.9em', width: '50%' }}>
                        <IndicatorPercentValue percent={data.dataResponse!.salesAchievementPercentMt} label='Atingimento' />
                      </div>
                      <div style={{ textAlign: 'center', width: '50%' }}>
                        <h4>Tendência </h4>
                        <h3><MoneySymbol value={data.dataResponse!.saleTrendValueMt} /></h3>
                      </div>
                    </DivH>
                  </Card>
                </IonCol>

                <IonCol size='6' sizeXs='5'>
                  <Card>
                    <h3 style={{ textAlign: 'center' }}>Margem</h3>
                    <DivH style={{ justifyContent: 'space-around' }}>
                      <div style={{ textAlign: 'center', width: '50%' }}>
                        <h4 >Meta </h4>
                        <h3><PercentSymbol value={data.dataResponse!.totalMarginGoalPercentMt} /></h3>
                      </div>
                      <div style={{ textAlign: 'center', width: '50%' }}>
                        <h4>Realizado </h4>
                        <h3><PercentSymbol value={data.dataResponse!.totalMarginPercentMt} /></h3>
                      </div>
                    </DivH>

                    <DivH style={{ justifyContent: 'space-around' }}>
                      <div style={{ textAlign: 'center', fontSize: '0.9em', width: '50%' }}>
                        {/* <IndicatorPercentValue percent={data.goalHeaderData!.marginAchievementPercentMt} label='Atingimento' /> */}
                      </div>
                      <div style={{ textAlign: 'center', width: '50%' }}>
                        <h4>Mark down </h4>
                        <h3><PercentSymbol value={data.dataResponse!.markDownPercentMt} /></h3>
                      </div>
                    </DivH>
                  </Card>
                </IonCol>
              </IonRow>
            </IonGrid>

            <div ref={divRef} >
              <div id="chart" /> 
     

              {chartContex &&
                <div style={{maxWidth : '30em', margin: 'auto'}}> 
                  <IonRange value={1} min={1} max={chartContex!.ionRangeMax} onIonChange={(e) => {
                    const lastIndex = chartContex!.itensPorTela + (e.detail.value as number)

                    const startIndex = lastIndex - chartContex!.itensPorTela 
                    chartContex!.chart!.zoomX(startIndex, lastIndex)
                  }}/>
                </div> 

                // <div ref={divScrollParentRef} onScroll={onScrollH} style={{ width: '100%', overflowY: 'hidden', overflowX: 'visible'}}>
                //   <div ref={divScrollRef} style={{ height: '0.6em', width: chartContex?.divScrollWidthPercent + '%' }}></div>
                // </div>
              }
            </div>
          </>
        }
      </div>

      <IonContent ref={divContainer}>
        {isLoadingData &&
          <Loading />
        }

        {!isLoadingData &&
          buildContent()
        }
      </IonContent>

      {!isLoadingData &&
        buildFooter()
      }

    </IonPage>
  );
};

export default Goal;