import {
  IonContent,
  IonInput,
  IonItem,
  IonPage,
  useIonToast
} from '@ionic/react'

import { useState } from 'react'
import { login as loginService } from '../../service/session'
import { DivEl, LoginButtomEl, MainDivEl } from './styled'
import logo from '../../../src/utils/img/logo-login.png'

interface Param {
  loginSuccess: Function
}

const Login: React.FC<Param> = ({loginSuccess}) => {
  const [present] = useIonToast()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const login = async () => {
    //TODO pegar valores do form
    try {
      await loginService(email, password)
      loginSuccess()
    } catch (error: any) {
      //TODO colocar sistema de mensagens e alertas.
      let mensagem = 'Usuário inválido !'

      if (error!.response!.status !== 401 && error.response.data && error.response.data.message) {
        mensagem = mensagem + error.response.data.message
      }

      present({
        message: mensagem,
        duration: 1_500,
        position: 'top'
      })
    }
  };

  return (
    <IonPage>
      <IonContent>
        <DivEl>
          <MainDivEl>
            <img style={{height: '12em', marginBottom: '2em'}} src={logo} alt=''></img>

            <form>

              <span>Email</span>

              <div style={{ display: 'flex', width: '100%', marginBottom: '2em' }}>
                <IonItem>
                  <IonInput
                    id='email'
                    name='email'
                    value={email}
                    placeholder='Digite seu e-mail'
                    onIonChange={(e: any) => setEmail(e.target.value)}>
                  </IonInput>
                </IonItem>
              </div>

              <span>Senha</span>

              <div style={{ display: 'flex', width: '100%' }}>
                <IonItem>
                  <IonInput
                    id='password'
                    name='password'
                    value={password}
                    type='password'
                    placeholder='Digite sua senha'
                    onIonChange={(e: any) => setPassword(e.target.value)}>
                  </IonInput>
                </IonItem>
              </div>
            </form>

            <LoginButtomEl type="button" onClick={login}>
              Entrar
            </LoginButtomEl>

          </MainDivEl>
        </DivEl>
      </IonContent>
    </IonPage>
  );
};

export default Login;
