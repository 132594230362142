
import { MasterMetric } from ".";
import { Store } from "../../dto/store/store";
import httpClient from "../../service/api";
import { defaultDateEndFilter, defaultDateStartFilter } from "../../utils/format-util";

export class OperatorsResponse {
  additionValueMt: number = 0
  cancellationValueMt: number = 0
  descountValueMt: number = 0
  id: number = 0
  label: string = ''
  currentPercentMt: number = 0
  totalSaleValueMt: number = 0
  couponValueMt: number = 0
  
}

export class OperatorsFilter {
  dateStart: string = defaultDateStartFilter().toISOString()
  dateEnd: string = defaultDateEndFilter().toISOString()

  masterMetric: MasterMetric = 'COUPON'
  stores: Store[] = []
  resultLimit: number = 30
}

export class OperatorsData {
  filter : OperatorsFilter = new OperatorsFilter()
  dataGridList? : OperatorsResponse[]
  date?:Date
  
  serieValues? : number[] = []
  categoriesId : number[] = []
}

export const findData = async (filter: OperatorsFilter) : Promise<OperatorsData> => {
  const data = new OperatorsData()
  data.filter = filter
  data.date = new Date()
  try {
    const response = await httpClient.post('/api/operator/process-period', filter);
    const dataResponse : OperatorsResponse[]  = response.data
    data.dataGridList = dataResponse

    data.serieValues = dataResponse.map(item => getCurrentValue(data, item))

    data.categoriesId = dataResponse.map(item => item.id)
  } catch (error: any) {
    data.dataGridList = []
    console.error(error)
  }

  return data;
}

export const getCurrentValue = (data: OperatorsData, item: OperatorsResponse) : number => {
  if (data.filter.masterMetric === 'SALE') {
    return item.totalSaleValueMt
  } else if (data.filter.masterMetric === 'CANCELLATION') {
    return item.cancellationValueMt
  } else if (data.filter.masterMetric === 'DESCOUNT') {
    return item.descountValueMt
  } else if (data.filter.masterMetric === 'COUPON') {
    return item.couponValueMt
  }
  return 0
}
