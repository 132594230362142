import { Segment } from "../../dto/segment";
import { Store } from "../../dto/store/store";
import { StoreResponse } from "../../dto/store/store-response";
import httpClient from "../../service/api";
import { defaultDateEndFilter, defaultDateStartFilter } from "../../utils/format-util";

export class StoreFilter {
  dateStart: string = defaultDateStartFilter().toISOString()
  dateEnd: string = defaultDateEndFilter().toISOString()
  stores: Store[] = []
  organizationIdAtr?: number
  segments?: Segment[]
}

export class StoreData {
  filter: StoreFilter = new StoreFilter()
  dataGridList?: StoreResponse[]
  categoriesStores: number[] = []
  totalSaleValueMt: number[] = []
  serieDirectSales: number[] = []
  serieGoal: number[] = []
  serieLosses: number[] = []
  serieTotalCost: number[] = []
  resultcalcTotalSaleValueMt: number = 0
  maxSale: number = 0
  date?: Date
}

export const findData = async (filter: StoreFilter): Promise<StoreData> => {
  const response = await httpClient.post('/api/store/process-period', filter);
  const dataResponse: StoreResponse[] = response.data
  const data = new StoreData()
  data.filter = filter

  data.dataGridList = dataResponse

  let resultcalcTotalSaleValueMt = 0;
  dataResponse.forEach((item) => {
    resultcalcTotalSaleValueMt += item.totalSaleValueMt
  })

  data.categoriesStores = dataResponse.map(item => item.store!.id)
  data.totalSaleValueMt = dataResponse.map(item => item.totalSaleValueMt)
  data.serieDirectSales = dataResponse.map(item => item.flexSaleValueMt)
  data.serieGoal = dataResponse.map(item => item.totalSaleGoalValueMt)
  data.serieLosses = dataResponse.map(item => item.lossValueMt)
  data.serieTotalCost = dataResponse.map(item => item.costValueMt)
  data.resultcalcTotalSaleValueMt = resultcalcTotalSaleValueMt

  data.maxSale = Math.max.apply(null, data.totalSaleValueMt)
  data.date = new Date()

  return data
}