import { PeriodResponse } from "../../dto/periods/periods-response";
import httpClient from "../../service/api";
import { defaultDateEndFilter, defaultDateStartFilter } from "../../utils/format-util";

export declare type AggregationType = 'HOUR' | 'DAY' | 'MONTH' | 'YEAR';

export class PeriodFilter {
    dateStart: string = defaultDateStartFilter().toISOString()
    dateEnd: string = defaultDateEndFilter().toISOString()
    
    aggregationType: AggregationType = 'HOUR'
}

export class PeriodData {
  filter : PeriodFilter = new PeriodFilter()
  date?:Date
  dataGridList? : PeriodResponse[]
  seriePdvSaleValueMt : number[] = []
  serieFlexSaleValueMt : number[] = []
  seriecostValueMt : number[] = []
  categorieLabelAggregation : string[] = []
  
  totalSaleValueMt : number = 0

  maxSale: number = 0
  maxHour: string = ''
  minSale: number = 0
  minHour: string = ''
  minIndex: number = 0

  maxValue:number = 0
}

export const findData = async (filter: PeriodFilter) : Promise<PeriodData> => {
  const response = await httpClient.post('/api/period/process-period', filter)
  const dataResponse : PeriodResponse[]  = response.data

  const data = new PeriodData()
  data.filter = filter
  data.dataGridList = dataResponse
  data.date = new Date()

  if (dataResponse.length === 0) {
    return data;
  }
  
  data.seriePdvSaleValueMt = dataResponse.map(item => item.pdvSaleValueMt)
  data.serieFlexSaleValueMt = dataResponse.map(item => item.flexSaleValueMt)
  data.seriecostValueMt = dataResponse.map(item => item.costValueMt)
  data.categorieLabelAggregation = dataResponse.map(item => item.labelAggregation)
  
  data.maxSale = Math.max.apply(null, data.seriePdvSaleValueMt)
  if (data.maxSale) {
    data.maxValue = data.maxSale * 1.05
  }
  data.maxHour = data.categorieLabelAggregation[data.seriePdvSaleValueMt.indexOf(data.seriePdvSaleValueMt.find(item => item === data.maxSale)!)]!.toString()
  
  data.minSale = Math.min.apply(null, data.seriePdvSaleValueMt)
  data.minHour = data.categorieLabelAggregation[data.seriePdvSaleValueMt.indexOf(data.seriePdvSaleValueMt.find(item => item === data.minSale)!)]!.toString()
  data.minIndex = data.seriePdvSaleValueMt.indexOf(data.minSale)

  return data;
}