import { PdvResponse } from "../../dto/pdv/pdv-response";
import { Store } from "../../dto/store/store";
import httpClient from "../../service/api";
import { defaultDateStartFilter } from "../../utils/format-util";
import { findAllStores } from "../home/filter/service";

export declare type PdvFilterOrderBy = 'SALE' | 'COUPON' | 'DESCOUNT' | 'CANCELLATION' | 'ADDITION' | 'BIPS'

export class PdvFilter {
  date: string = defaultDateStartFilter().toISOString()
  startHour: number = 0
  endHour: number = 23
  store?: Store
  orderBy: PdvFilterOrderBy = 'SALE'
}

export class PdvData {
  filter: PdvFilter = new PdvFilter()
  dataGridList?: PdvResponse[]
  date?:Date

  serieValues: number[] = []
  categorieId: number[] = []
  allStores: Store[] = []
  orderByValue: any;
}

export const findData = async (filter: PdvFilter): Promise<PdvData> => {
  const data = new PdvData()
  data.date = new Date()

  data.allStores = await findAllStores()
  //Quando a loja é nulo, colocamos a primeira loja da lista como padrão.
  if (!filter.store) {
    filter.store = data.allStores[0]
  }
  
  const response = await httpClient.post('/api/pdv/process-period', filter);
  const dataResponse: PdvResponse[] = response.data

  data.filter = filter
  data.dataGridList = dataResponse

  if (filter.orderBy === 'SALE') {
    data.orderByValue = {
      getValue: (item: PdvResponse) => item.totalSaleValueMt,
      label: 'Venda'
    }
  } else if (filter.orderBy === 'DESCOUNT') {
    data.orderByValue = {
      getValue: (item: PdvResponse) => item.descountValueMt,
      label: 'Descontos'
    }
  } else if (filter.orderBy === 'CANCELLATION') {
    data.orderByValue = {
      getValue: (item: PdvResponse) => item.cancellationValueMt,
      label: 'Descontos'
    }
  } else if (filter.orderBy === 'ADDITION') {
    data.orderByValue = {
      getValue: (item: PdvResponse) => item.additionValueMt,
      label: 'Acréscimos'
    }
  } else if (filter.orderBy === 'COUPON') {
    data.orderByValue = {
      getValue: (item: PdvResponse) => item.couponAmountMt,
      label: 'Cupom'
    }
  } else if (filter.orderBy === 'BIPS') {
    data.orderByValue = {
      getValue: (item: PdvResponse) => item.bipsAmountMt,
      label: 'Qtn Bips'
    }
  }

  data.serieValues = dataResponse.map(item => { return data.orderByValue.getValue(item)})

  data.categorieId = dataResponse.map(item => item.id)

  return data;
}
